import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Menu.module.scss';
import {Icon, MenuItem, Link} from 'components';

import {versions} from 'contexts/version';

const Menu = ({
    className,
    items,
    title,
    icon,
    selection,
    onOpen,
    onClose,
    isOpen,
}) => {
    const firstChildWithChildren = items.findIndex(item => item.hasChildren);

    return (
        <div className={classNames(styles.Menu, className)}>
            <div className={classNames({[styles.hide]: !isOpen})}>
                <Link className={styles.back} onClick={onClose} to="/">
                    <Icon className={styles.icon} name="arrow" size={18} />
                    Home
                </Link>
                {Boolean(title) && <p className={styles.title}>{title}</p>}
                {Boolean(items.length) && (
                    <ul>
                        {items.map((item, index) => (
                            <MenuItem
                                {...item}
                                className={classNames(
                                    firstChildWithChildren === index &&
                                        styles.separator
                                )}
                                key={item.path + item.name}
                                selection={`/${selection
                                    .split('/')
                                    .filter(
                                        el =>
                                            Boolean(el) &&
                                            !versions.filter(v => v.slug === el)
                                                .length
                                    )
                                    .join('/')}`}
                            />
                        ))}
                    </ul>
                )}
            </div>

            <button
                onClick={onOpen}
                className={classNames(styles.parent, styles.zero, {
                    [styles.hide]: isOpen,
                })}
            >
                {icon && <Icon className={styles.icon} name={icon} size={24} />}
                {title}
            </button>
        </div>
    );
};

Menu.defaultProps = {
    items: [],
    selection: '',
};

Menu.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            children: PropTypes.any,
            className: PropTypes.string,
            depth: PropTypes.oneOf([0, 1, 2, 3]),
            exact: PropTypes.bool,
            hasChildren: PropTypes.bool,
            method: PropTypes.string,
            name: PropTypes.node,
            path: PropTypes.string,
            selection: PropTypes.string,
        })
    ),
    icon: PropTypes.string,
    selection: PropTypes.string,
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};

export default Menu;
