import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon} from 'components';
import styles from './Callout.module.scss';

const CALLOUT_TYPES = ['important', 'info', 'success', 'tip', 'warning'];

const Callout = ({children, content, className, label, type, withIcon}) => {
    const body = content || children;

    return (
        Boolean(body || label || withIcon) && (
            <div
                className={classNames(styles.Callout, styles[type], className)}
            >
                <div className={styles.emphasis} />
                <div className={styles.content}>
                    {withIcon && <Icon name="info" size={16} />}
                    {label && <span className={styles.label}>{label}</span>}
                    {body && <span className={styles.body}>{body}</span>}
                </div>
            </div>
        )
    );
};

Callout.defaultProps = {
    type: 'tip',
};

Callout.propTypes = {
    /** Alternative way to pass in content */
    children: PropTypes.any,
    /** MDX-compatible way to pass in content */
    content: PropTypes.any,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Text for the bolded label */
    label: PropTypes.string,
    /** Applies border color */
    type: PropTypes.oneOf(CALLOUT_TYPES).isRequired,
    /** Whether or not to show the icon */
    withIcon: PropTypes.bool,
};

export default Callout;
