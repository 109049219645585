import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import styles from './ActionBar.module.scss';
import {CopyTarget, Icon, Toast} from 'components';
import {useCopyText, useToast} from 'hooks';

const ActionBar = ({
    className,
    codeSample,
    isModal,
    onSelectTab,
    onToggleModal,
    selectedTab,
    tabs,
}) => {
    const [displayToast, setDisplayToast] = useToast();
    const toggleToast = useCallback(() => setDisplayToast(true), [
        setDisplayToast,
    ]);
    const {copyTargetRef, copyFocusRef, copyText} = useCopyText(toggleToast);

    return (
        <div
            className={classNames(
                styles.ActionBar,
                className,
                isModal ? styles.modal : styles.embedded
            )}
        >
            <div className={styles.buttons}>
                {!isModal && Boolean(tabs && tabs.length) && (
                    <div className={styles.tabs}>
                        {tabs.map(tab => (
                            <button
                                className={classNames(styles.tab, {
                                    [styles.selectedTab]: tab === selectedTab,
                                })}
                                key={tab}
                                onClick={onSelectTab}
                                value={tab}
                                type="button"
                            >
                                {startCase(tab)}
                            </button>
                        ))}
                    </div>
                )}
                <button
                    className={classNames(
                        styles.button,
                        isModal ? styles.close : styles.fullscreen
                    )}
                    type="button"
                    onClick={onToggleModal}
                >
                    <Icon
                        className={styles.icon}
                        name={isModal ? 'close' : 'fullscreen'}
                        size={16}
                    />
                    {isModal && 'Close'}
                </button>
                <button
                    className={classNames(styles.button, styles.copy)}
                    onClick={copyText}
                    ref={copyFocusRef}
                    type="button"
                >
                    Copy
                    <Toast displayToast={displayToast} />
                </button>
            </div>
            {isModal && Boolean(tabs && tabs.length) && (
                <div className={styles.tabs}>
                    {tabs.map(tab => (
                        <button
                            className={classNames(styles.tab, {
                                [styles.selectedTab]: tab === selectedTab,
                            })}
                            key={tab}
                            onClick={onSelectTab}
                            value={tab}
                            type="button"
                        >
                            {startCase(tab)}
                        </button>
                    ))}
                </div>
            )}
            <CopyTarget value={codeSample} ref={copyTargetRef} />
        </div>
    );
};

ActionBar.propTypes = {
    className: PropTypes.string,
    codeSample: PropTypes.string,
    isModal: PropTypes.bool,
    onSelectTab: PropTypes.func,
    onToggleModal: PropTypes.func,
    selectedTab: PropTypes.string,
    tabs: PropTypes.arrayOf(PropTypes.string),
};

export default ActionBar;
