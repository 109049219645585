import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import styles from './CopyTarget.module.scss';
import classNames from 'classnames';

const CopyTarget = forwardRef(({className, value}, ref) => {
    return (
        <textarea
            className={classNames(styles.CopyTarget, className)}
            ref={ref}
            value={value}
            readOnly
        />
    );
});

CopyTarget.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
};

export default CopyTarget;
