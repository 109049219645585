import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.scss';
import classNames from 'classnames';
import icons from './data';

function getPreserveAspectRatio({left, right, bottom, top}) {
    return [
        `x${(left && 'Min') || (right && 'Max') || 'Mid'}`,
        `Y${(top && 'Min') || (bottom && 'Max') || 'Mid'}`,
        ' meet',
    ].join('');
}

const Icon = ({
    className,
    fill,
    height,
    horizontalAlign,
    name,
    role,
    size,
    verticalAlign,
    width,
    ...props
}) => {
    const {path, x, y, title, description} = icons[name] || props;

    if (!path || !x || !y) {
        return null;
    }

    return (
        <svg
            {...props}
            aria-labelledby={`${title ? `Icon-title--${name}` : ''}${
                title && description ? ' ' : ''
            }${description ? `Icon-description--${name}` : ''}`}
            className={classNames(styles.Icon, styles[name], className)}
            height={height || size || (width ? width * (y / x) : '100%')}
            width={width || size || (height ? height * (x / y) : '100%')}
            preserveAspectRatio={getPreserveAspectRatio({
                left: horizontalAlign === 'left',
                right: horizontalAlign === 'right',
                bottom: verticalAlign === 'bottom',
                top: verticalAlign === 'top',
            })}
            role={role}
            viewBox={`0 0 ${x} ${y}`}
        >
            {title && <title id={`Icon-title--${name}`}>{title}</title>}
            {description && (
                <desc id={`Icon-description--${name}`}>{description}</desc>
            )}
            <path fill={fill} fillRule="evenodd" d={path} role="presentation" />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
    role: 'img',
};

Icon.propTypes = {
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Accessible description (used by screen readers; not displayed) */
    description: PropTypes.string,
    /** Color of icon */
    fill: PropTypes.string,
    /** Set height of icon. If width is excluded, it will be automatically calculated to preserve the aspect ratio. */
    height: PropTypes.number,
    /** When the icon is narrower than the given width or size, set the horizontal alignment */
    horizontalAlign: PropTypes.oneOf(['left', 'right']),
    /** Select an icon from the icon library in 'components/Icon/data' */
    name: PropTypes.string,
    /** Pass in an optimized SVG path in lieu of a name of an icon from the icon library */
    path: PropTypes.string,
    /** Accessible role (assists screen readers) */
    role: PropTypes.string,
    /** Accessible title (used by screen readers; not displayed) */
    title: PropTypes.string,
    /** If height or width are not set, sets height and/or width to this px value; by default, irregular shapes will center horizontally and vertically unless otherwise specified */
    size: PropTypes.number,
    /** When the icon is smaller than the given height or size, set the vertical alignment */
    verticalAlign: PropTypes.oneOf(['top', 'bottom']),
    /** Set width of icon. If height is excluded, it will be automatically calculated to preserve the aspect ratio. */
    width: PropTypes.number,
};

export default Icon;
