import docs from 'swagger';
import routes from 'routes';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

import {versions} from 'contexts/version';
import {
    Root,
    Guides,
    Reports,
    Account,
    Support,
    Scenarios,
    GetStarted,
    ReleaseNotes,
} from 'pages';

const buildSubMenu = (data, route = false, version = false) =>
    Object.keys(get(data, version, data))
        .filter(
            name =>
                typeof get(data, [version, name].filter(Boolean)) === 'function'
        )
        .map(page => ({
            depth: 0,
            name: startCase(page),
            path: [route, kebabCase(page)].filter(Boolean).join('/'),
            versionLess: !version,
            icon: kebabCase(page),
        }));

const getMenu = version => [
    {
        title: 'Get Started',
        icon: 'pin',
        routes: [routes.getStarted],
        menu: buildSubMenu(GetStarted, routes.getStarted),
    },
    {
        title: 'Scenarios',
        icon: 'scenarios',
        routes: [routes.scenarios],
        menu: buildSubMenu(Scenarios, routes.scenarios, version),
    },
    {
        title: 'Integration Docs',
        icon: 'docs',
        routes: [routes.sdk.overview, routes.guides, routes.reference.overview],
        menu: [
            {
                depth: 0,
                name: 'API',
                icon: 'api',
                path: routes.reference.overview,
                readOnly: true,
            },
            {
                depth: 1,
                name: 'API Overview',
                path: routes.reference.overview,
            },
            {
                depth: 1,
                name: 'Authentication',
                path: routes.reference.authentication,
            },
            ...flatten(
                docs.menu(version).map(type =>
                    docs.uiByName(version)[type].reduce(
                        (all, item) =>
                            all.concat({
                                ...item,
                                path: `${routes.reference.overview}/${type}#${item.hash}`,
                            }),
                        []
                    )
                )
            ),
            {
                depth: 0,
                name: 'iOS SDK',
                icon: 'sdk',
                path: routes.sdk.overview,
            },
            ...buildSubMenu(Guides, routes.guides, version),
        ],
    },
    {
        title: 'Reports',
        icon: 'receipt',
        routes: [routes.reports],
        menu: buildSubMenu(Reports, routes.reports),
    },
    {
        title: 'Account',
        routes: [routes.account],
        menu: buildSubMenu(Account, routes.account),
    },
    {
        title: 'Support',
        icon: 'report',
        routes: [routes.support],
        menu: buildSubMenu(Support, routes.support),
    },
    {
        title: 'Release Notes',
        routes: [routes.releaseNotes],
        menu: buildSubMenu(ReleaseNotes, routes.releaseNotes),
    },
    ...buildSubMenu(Root),
];

export default versions.reduce(
    (acc, version) => ({
        ...acc,
        [version.slug]: getMenu(version.slug),
    }),
    {}
);
