import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {mmddyyyy} from 'utils/format';
import {withRouter} from 'react-router';

export const versions = [
    {
        slug: 'v2.0',
        display: '2.0',
        updated: mmddyyyy(new Date(process.env.BUILD_DATE)),
    },
    {
        slug: 'v1.0',
        display: '1.0',
        updated: '1/15/2020',
    },
];

const VersionContext = React.createContext();

export const VersionContextProvider = withRouter(({children, match}) => {
    const [version, setVersion] = useState(
        versions.find(
            v => v.slug === get(match, 'params.version', versions[0].slug)
        )
    );

    return (
        <VersionContext.Provider
            value={{
                version,
                versions,
                setVersion,
            }}
        >
            {children}
        </VersionContext.Provider>
    );
});

export const withVersion = Component => props => (
    <VersionContext.Consumer>
        {context => <Component {...props} {...context} />}
    </VersionContext.Consumer>
);

VersionContextProvider.propTypes = {
    children: PropTypes.node,
};

export default VersionContext;
