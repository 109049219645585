import React from 'react';
import PropTypes from 'prop-types';
import {Snippet} from 'components';
import {API_URL, SUPPORTED_METHODS} from 'lang';

/* eslint-disable no-confusing-arrow */
const applyParameters = {
    formData: formData =>
        formData
            .map(param => `\n    -F ${param.name}=${param.example} \\`)
            .join(''),
    query: queryData =>
        queryData.length
            ? `?${queryData
                  .map(query => `${query.name}=${query.example}`)
                  .join('&')}`
            : '',
    path: (endpoint, parameters) => {
        return endpoint
            .split('/')
            .map(param =>
                param.startsWith('{')
                    ? parameters.find(item => `{${item.name}}` === param) &&
                      parameters.find(item => `{${item.name}}` === param)
                          .example
                    : param
            )
            .join('/');
    },
};
/* eslint-enable no-confusing-arrow */

const SampleRequest = ({
    className,
    docs: {consumes, parameters = []},
    endpoint = '',
    method,
}) => {
    if (!endpoint) return null; // eslint-disable-line curly

    return (
        <div className={className}>
            <Snippet language="curl">
                {`
curl -s -X ${method.toUpperCase()} \\
    -H "Authorization: Bearer {{access_token}}"  \\${
        consumes
            ? `\n    -H "Content-Type: ${consumes[consumes.length - 1]}" \\`
            : ''
    }${applyParameters.formData(
                    parameters.filter(
                        param => param.example && param.in === 'formData'
                    )
                )}
    ${API_URL}${applyParameters.path(
                    endpoint,
                    parameters
                )}${applyParameters.query(
                    parameters.filter(
                        param => param.example && param.in === 'query'
                    )
                )}
                `}
            </Snippet>
        </div>
    );
};

SampleRequest.defaultProps = {
    docs: {},
    method: 'get',
};

SampleRequest.propTypes = {
    className: PropTypes.string,
    docs: PropTypes.shape({
        consumes: PropTypes.array,
        parameters: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string,
                in: PropTypes.string,
                name: PropTypes.string,
                required: PropTypes.bool,
                type: PropTypes.string,
                example: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.bool,
                    PropTypes.number,
                ]),
            })
        ),
    }),
    endpoint: PropTypes.string,
    method: PropTypes.oneOf(Object.keys(SUPPORTED_METHODS)).isRequired,
};

export default SampleRequest;
