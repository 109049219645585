import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import styles from './ParameterTable.module.scss';
import {Table} from 'components';

const requiredFirst = (a, b) => {
    return a.required && !b.required ? -1 : b.required && !a.required ? 1 : 0;
};

const ParameterTable = ({className, data}) => {
    const headers = [
        'name',
        'description',
        'type',
        'dataType',
        data && data.find(parameter => parameter.example) && 'example',
    ].filter(Boolean);

    return data && data.length ? (
        <div className={classNames(styles.ParameterTable, className)}>
            <Table
                className={styles.table}
                headers={headers.map(attr => startCase(attr))}
                data={data.sort(requiredFirst).map(parameter =>
                    headers.map((header, index) =>
                        index !== 0
                            ? {
                                  component: () => (
                                      <div className={styles.secondary}>
                                          <p className={styles.name}>
                                              {parameter.name}
                                          </p>
                                          <p className={styles.optional}>
                                              {!parameter.required &&
                                                  'optional'}
                                          </p>
                                          <p className={styles.content}>
                                              {parameter[header]}
                                          </p>
                                      </div>
                                  ),
                                  className: styles[header],
                              }
                            : {
                                  component: () => (
                                      <div className={styles.primary}>
                                          <p className={styles.name}>
                                              {parameter.name}
                                          </p>
                                          <strong className={styles.optional}>
                                              {!parameter.required &&
                                                  'optional'}
                                          </strong>
                                      </div>
                                  ),
                                  className: styles[header],
                              }
                    )
                )}
            />
        </div>
    ) : null;
};

ParameterTable.propTypes = {
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Each item in the array represents a row in the table */
    data: PropTypes.arrayOf(
        PropTypes.shape({
            dataType: PropTypes.string,
            description: PropTypes.string,
            example: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
            type: PropTypes.string,
            required: PropTypes.bool,
        })
    ),
};

export default ParameterTable;
