import React from 'react';
import classNames from 'classnames';
import {MDXProvider} from '@mdx-js/react';
import styles from './Markdown.module.scss';
import {Anchor, Link, Snippet} from 'components';

/* eslint-disable react/prop-types */
const components = {
    a: Link,
    code: Snippet,
    h1: ({children}) => <Anchor className="headerExtraLarge" name={children} />,
    h2: ({children}) => <Anchor className="headerLarge" name={children} />,
    h3: ({children}) => <Anchor className="headerMedium" name={children} />,
    h4: ({children}) => <Anchor className="headerSmall" name={children} />,
    p: ({children}) => <p className="copy">{children}</p>,
    wrapper: ({children, disableGutters}) => (
        <div
            className={classNames(styles.Markdown, {
                [styles.gutters]: !disableGutters,
            })}
        >
            {children}
        </div>
    ),
};
/* eslint-enable react/prop-types */

const Markdown = (WrappedComponent, additional) => props => (
    <MDXProvider components={components}>
        <WrappedComponent {...additional} {...props} />
    </MDXProvider>
);

export default Markdown;
