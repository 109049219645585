import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {AdditionalSupport, Anchor, Redirect} from 'components';
import docs from 'swagger';
import routes from 'routes';
import {withRouter} from 'react-router';
import styles from './Reference.module.scss';
import {withVersion} from 'contexts/version';

const getStyles = {
    0: 'headerExtraLarge',
    1: 'headerExtraLarge',
    2: 'headerExtraLarge headerSeparator',
    3: 'headerLarge',
};

const docsByName = version =>
    docs.menu(version).reduce(
        (all, currentDoc) => ({
            ...all,
            [currentDoc]: docs
                .uiByName(version)
                [currentDoc].map(({hash, name, depth, component}) => (
                    <Fragment key={hash + name}>
                        <Anchor
                            name={name}
                            hash={hash}
                            className={getStyles[depth]}
                        />
                        {component}
                    </Fragment>
                )),
        }),
        {}
    );

export const Reference = ({
    location,
    match: {
        params: {docs: currentDocs},
    },
    version,
}) => {
    return docsByName(version.slug)[currentDocs] ? (
        <div className={styles.Reference}>
            {docsByName(version.slug)[currentDocs]}
            <AdditionalSupport />
            <div className={styles.spacer} />
        </div>
    ) : (
        <Redirect
            to={{
                pathname: `${routes.reference.overview}/${
                    Object.keys(docsByName(version.slug))[0]
                }`,
                state: {
                    from: location,
                },
            }}
        />
    );
};

Reference.propTypes = {
    location: PropTypes.object,
    match: PropTypes.shape({
        params: PropTypes.shape({
            docs: PropTypes.string,
        }),
    }),
    version: PropTypes.object,
};

export default withRouter(withVersion(Reference));
