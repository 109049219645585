import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import {AdditionalSupport, Anchor, Link, Table} from 'components';
import styles from './APIOverview.module.scss';
import {SPOTHERO} from 'lang';
import routes, {supportEmail} from 'routes';
import docs from 'swagger';
import flattenDepth from 'lodash/flattenDepth';
import {withVersion} from 'contexts/version';
import {Versioning} from 'pages';

const allEndpoints = version =>
    flattenDepth(
        docs
            .menu(version)
            .map(name => docs[version][name])
            .map(docByName =>
                Object.keys(docByName.paths).map(path => {
                    return Object.keys(docByName.paths[path]).map(method => {
                        const {
                            description,
                            summary,
                            operationId,
                        } = docByName.paths[path][method];
                        const title = docByName.info.title.toLowerCase();
                        const basePath = path
                            .split('/')
                            .slice(0, 4)
                            .join('/');
                        const action = path
                            .split('/')
                            .slice(4)
                            .join('/');

                        return [
                            {
                                component: () => (
                                    <Link
                                        className={styles.link}
                                        forceAnchor
                                        to={`${routes.reference.overview}/${title}#${operationId}`}
                                    >
                                        <div className={styles.flex}>
                                            <p
                                                className={classNames(
                                                    styles.method,
                                                    styles[method]
                                                )}
                                            >
                                                {method}
                                            </p>
                                            <p className={styles.path}>
                                                <span>{`${basePath}${
                                                    action ? '/' : ''
                                                }`}</span>
                                                {action && (
                                                    <span>{action}</span>
                                                )}
                                            </p>
                                        </div>
                                    </Link>
                                ),
                                className: styles.endpoint,
                            },
                            {
                                component: () => (
                                    <Link
                                        className={classNames(
                                            styles.link,
                                            styles.description
                                        )}
                                        forceAnchor
                                        to={`${routes.reference.overview}/${title}#${operationId}`}
                                    >
                                        {description || summary}
                                    </Link>
                                ),
                                className: styles.description,
                            },
                        ];
                    });
                })
            ),
        2
    );

const NAVIGATION_ITEMS = ['Available APIs', 'Rate Limiting', 'API Versioning'];

const APIOverview = ({className, version}) => (
    <div className={classNames(styles.APIOverview, className)}>
        <Anchor className="headerExtraLarge" name="API Overview" />
        <p className="copy">
            Welcome to the SpotHero Parking Mobility API! By utilizing the API,
            you are leveraging the largest network of off-street parking in
            North America, all while having the ability to customize the user
            experience to your service. The Parking Mobility API will get you
            set up with reservation functionality to allow your users to search
            and book parking.
        </p>
        <p className="copy">
            {`Your use of ${SPOTHERO}'s APIs, SDKs, other developer services, and associated software is subject to the `}
            <Link
                className={styles.license}
                to={routes.license}
                target="_blank"
                versionLess
            >
                API License Agreement
            </Link>
            .
        </p>
        <ul className={styles.links}>
            <p className="headerLarge">On this page:</p>
            {NAVIGATION_ITEMS.map(link => (
                <li className={styles.link} key={link}>
                    <Link forceAnchor to={`#${camelCase(link)}`} versionLess>
                        {link}
                    </Link>
                </li>
            ))}
        </ul>
        <Anchor className="headerLarge" name="Available APIs" />
        <Table
            headers={['API', 'Description']}
            data={allEndpoints(version.slug)}
        />
        <Anchor className="headerLarge" name="Rate Limiting" />
        <p className="copy">
            Access to our API is Rate Limited. When a client exceeds their
            allotment a <code>HTTP/1.1 429 TOO MANY REQUESTS</code> response
            code is returned.
        </p>
        <p className="copy">
            {`Rate Limiting is determined on a per-client basis and is highly flexible. Contact the SpotHero team at `}
            <Link to={routes.emailSupport}>{supportEmail}</Link>
            {` to request a rate limit increase.`}
        </p>
        <Versioning />
        <AdditionalSupport />
    </div>
);

APIOverview.propTypes = {
    version: PropTypes.shape({
        slug: PropTypes.string,
    }),
    className: PropTypes.string,
};

export default withVersion(APIOverview);
