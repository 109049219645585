import React from 'react';
import PropTypes from 'prop-types';
import styles from './SdkOverview.module.scss';
import classNames from 'classnames';
import {Anchor, AdditionalSupport, Link} from 'components';
import routes from 'routes';

const SdkOverview = ({className}) => {
    return (
        <div className={classNames(styles.SdkOverview, className)}>
            <Anchor className="headerExtraLarge" name="iOS SDK Overview" />
            <p className="copy">
                The SpotHero Parking Mobility SDK will allow your users to
                purchase parking through SpotHero with minimal setup by your
                development team.
            </p>
            <Link className={styles.button} isButton to={routes.sdk.ios}>
                View Docs
            </Link>
            <p className="copy">
                Interested in using the SDK? Request access below!
            </p>
            <Link
                className={styles.button}
                isButton
                forceAnchor
                to={routes.requestSDKAccess}
            >
                Request SDK Access
            </Link>
            <p className="copy">
                <em>
                    Android SDK is not yet available, but we&apos;re working on
                    adding new features every day!
                </em>
            </p>
            <img
                alt="Sample images of the home screen, results, and facility details from the iOS SDK"
                className={styles.image}
                src="https://res.cloudinary.com/spothero/image/upload/v1567789175/developer-portal/iosSdk.png"
            />
            <AdditionalSupport />
        </div>
    );
};

SdkOverview.propTypes = {
    className: PropTypes.string,
};

export default SdkOverview;
