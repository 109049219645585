import {Swagger2} from 'components';
import importAll from 'utils/importAll';

const swagger = importAll({
    context: require.context('../03-swagger/', true, /.yaml$/),
});
const menu = version => Object.keys(swagger[version]);
const uiByName = version =>
    menu(version).reduce(
        (all, current) => ({
            ...all,
            [current]: Swagger2({docs: swagger[version][current]}),
        }),
        {}
    );

export default {
    ...swagger,
    menu,
    uiByName,
};
