import {useState} from 'react';

/**
 * Collection of swipe direction booleans
 * @typedef {Object} SwipeDirection
 * @property {boolean} up - True if swipe direction included up
 * @property {boolean} down - True if swipe direction included down
 * @property {boolean} left - True if swipe direction included left
 * @property {boolean} right - True if swipe direction included right
 *
 * @callback onSwipe
 * @param {SwipeDirection}
 */

/**
 * Captures swipe direction(s) and passes them to onSwipe callback
 * @param {onSwipe} onSwipe - Callback
 * @returns {Object} - handleStartMove and handleEndMove to pass to target element
 */
export default function useSwipe(onSwipe) {
    const [xStart, setXStart] = useState(0);
    const [yStart, setYStart] = useState(0);

    function handleStartMove(evt) {
        setXStart(evt.pageX || evt.touches[0].clientX);
        setYStart(evt.pageY || evt.touches[0].clientY);
    }

    function handleEndMove(evt) {
        const xEnd = evt.pageX || evt.changedTouches[0].clientX;
        const yEnd = evt.pageY || evt.changedTouches[0].clientY;
        const swipe = {};

        if (xEnd > xStart) {
            swipe.right = true;
        } else if (xEnd < xStart) {
            swipe.left = true;
        }

        if (yEnd < yStart) {
            swipe.up = true;
        } else if (yEnd > yStart) {
            swipe.down = true;
        }

        onSwipe(swipe);
    }

    return {
        handleStartMove,
        handleEndMove,
    };
}
