/**
 * Returns a string, in plain english, based on a number
 * @example depthClassName(0) => 'zero'
 * @param {Depth} depth - Number
 * @returns {String} zero as a string
 */
export default function depthClassName(depth = false) {
    // 0 number is considered a falsey
    if (depth === false) {
        return '';
    }

    switch (depth) {
        case 0:
            return 'zero';
        case 1:
            return 'one';
        case 2:
            return 'two';
        case 3:
            return 'three';
    }
}
