import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import {withRouter} from 'react-router';
import {Icon, Link, NavLink} from 'components';
import routes from 'routes';
import styles from './Header.module.scss';
import {SidebarContext} from 'contexts';
import {versions} from 'contexts/version';

const headerLinks = [
    {
        to: routes.home,
        title: 'Overview',
    },
    {
        to: routes.reference.overview,
        title: 'Docs',
    },
    {
        to: routes.reference.authentication,
        title: 'Request API Key',
        className: styles['link--callout'],
    },
];

const Header = ({menus, location: {pathname, hash}}) => {
    const menu = flatten(menus.map(each => each.menu || []));
    const versionLessPathName = `/${pathname
        .split('/')
        .filter(Boolean)
        .filter(path => !versions.filter(v => v.slug === path).length)
        .join('/')}`;
    const selection = versionLessPathName + hash;
    const currentTitle = menu.length
        ? get(
              menu.find(
                  item => item.path.split('_')[0] === selection.split('_')[0] // match section
              ),
              'name',
              get(
                  menu.find(
                      item =>
                          item.path.split('#')[0] === selection.split('#')[0] // match page
                  ),
                  'name'
              )
          ) || 'Overview'
        : null;

    return (
        <header className={styles.Header} role="banner">
            <span className={styles.left}>
                <Link className={styles.logo} to={routes.home}>
                    <div className={styles.iconContainer}>
                        <Icon className={styles.icon} name="logotype" />
                    </div>
                    <span className={styles.developers}>Developer</span>
                </Link>
            </span>
            <span className={styles.right}>
                {headerLinks.map(({className, title, ...routeProps}) => (
                    <NavLink
                        {...routeProps}
                        className={classNames(styles.link, className)}
                        exact
                        key={title}
                    >
                        {title}
                    </NavLink>
                ))}
                {currentTitle && (
                    <SidebarContext.Consumer>
                        {({isOpen, onToggle}) => (
                            <div
                                className={styles.selection}
                                onClick={onToggle}
                            >
                                <span>{currentTitle}</span>
                                <Icon
                                    className={classNames(
                                        styles.toggle,
                                        styles[isOpen]
                                    )}
                                    name="arrow"
                                    size={22}
                                />
                            </div>
                        )}
                    </SidebarContext.Consumer>
                )}
            </span>
        </header>
    );
};

Header.defaultProps = {
    menus: [],
};

Header.propTypes = {
    menus: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            menu: PropTypes.arrayOf(
                PropTypes.shape({
                    component: PropTypes.element,
                    depth: PropTypes.number,
                    exact: PropTypes.bool,
                    hash: PropTypes.string,
                    name: PropTypes.string,
                    path: PropTypes.string,
                    readOnly: PropTypes.bool,
                })
            ),
        })
    ),
    location: PropTypes.shape({
        pathname: PropTypes.string,
        hash: PropTypes.string,
    }),
};

export default withRouter(Header);
