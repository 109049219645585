import path from 'path';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * Used to statically import modules from a directory at build time
 * https://webpack.js.org/guides/dependency-management/
 * @param {object} options.context pass in require.context with directory name, recursive bool, and regex
 * @param {array} options.excludes (optional) array of filePaths to exclude from match
 * @example importAll({
 *   context: require.context('../Guides/', true, /.mdx$/),
 *   excludes: ['./index.js']
 * }),
 * @return {object} object of components mapped to their directory name
 */
export default function importAll({context, excludes = []}) {
    return context
        .keys()
        .filter(key => !excludes.find(filePath => filePath === key))
        .reduce((all, key) => {
            const component = get(context(key), 'default', context(key));
            const directory = path.dirname(key).substr(2);
            const filename = path.basename(key).split('.')[0];
            const directoryNameSpace = directory
                .split('/')
                .filter(dir => dir !== filename);
            const name =
                directoryNameSpace === filename
                    ? filename
                    : [...directoryNameSpace, filename];

            return set(
                {
                    ...all,
                    ...((context(key).meta || all.meta) && {
                        meta: {
                            ...all.meta,
                            ...(context(key).meta &&
                                set(all.meta || {}, name, context(key).meta)),
                        },
                    }),
                },
                name,
                component
            );
        }, {});
}
