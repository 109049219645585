import React from 'react';
import PropTypes from 'prop-types';
import styles from './Column.module.scss';
import classNames from 'classnames';

export const Column = ({children, className}) => {
    return (
        <div className={classNames(styles.Column, className)}>{children}</div>
    );
};

Column.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default Column;
