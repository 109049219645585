
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import DSM from 'components/dsm';
export const meta = {
  title: 'Webhooks',
  content: 'Stay up-to-date with changes to your customers\' reservations.',
  buttonTitle: 'View Links Docs'
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Webhooks`}</h1>
    <p>{`In the case that a renter or SpotHero customer service cancels or updates a rental in SpotHero’s system, or SpotHero has determined that the reservation needs to be refunded, then SpotHero will send the corresponding webhook request to the partner. `}</p>
    <p>{`Webhooks have been implemented to communicate back to the partner’s system for cancellations, refunds and reservation modifications that have taken place on SpotHero’s side. You will need to receive the webhook requests based on the following suggested formats.`}</p>
    <p>{`Affiliate links are the easiest way to supply parking to your users. An `}<strong parentName="p">{`affiliate link`}</strong>{` is a referral URL that can send traffic from anywhere to SpotHero’s app or website for users to complete checkout.`}</p>
    <h2>{`Webhook Reference`}</h2>
    <p>{`All webhooks are based on HTTP REST principles.  Since these requests indicate state changes for a specific reservation, all webhooks are implemented as HTTP POST operations containing `}<inlineCode parentName="p">{`Content-Type: application/json`}</inlineCode>{` formatted request bodies.`}</p>
    <p>{`The request method and payload documented below must be adhered to, but the host and path are configurable by partner.`}</p>
    <h3>{`Cancel Reservation`}</h3>
    <h4>{`Example Endpoint`}</h4>
    <p><inlineCode parentName="p">{`yourdomain.com/partner-reservations/cancel/`}</inlineCode></p>
    <h4>{`Request Payload`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    “reservation_id” : 123
}
`}</code></pre>
    <h3>{`Refund Reservation`}</h3>
    <h4>{`Example Endpoint`}</h4>
    <p><inlineCode parentName="p">{`yourdomain.com/partner-reservations/refund/`}</inlineCode></p>
    <h4>{`Request Payload`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    “reservation_id”: 123
}
`}</code></pre>
    <h3>{`Modify Reservation`}</h3>
    <h4>{`Example Endpoint`}</h4>
    <p><inlineCode parentName="p">{`yourdomain.com/partner-reservations/update/`}</inlineCode></p>
    <h4>{`Request Payload`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    “reservation_id”: 123,
    “starts”: “2018-03-01T08:00:00”
    “ends”: “2018-03-01T14:00:00”
    “price”: 2500
    “spothero_charged_amount_net”: 500
}
`}</code></pre>
    <DSM.Callout type="info">
      <DSM.Row>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`The `}<inlineCode parentName="p">{`starts`}</inlineCode>{` and `}<inlineCode parentName="p">{`ends`}</inlineCode>{` datetimes are provided in the ISO-8601 format `}<inlineCode parentName="p">{`YYYY-dd-MMThh:mm:ss`}</inlineCode>{` and are assumed to be in the timezone of the reservation location.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Both the `}<inlineCode parentName="p">{`price`}</inlineCode>{` and `}<inlineCode parentName="p">{`spothero_charged_amount_net`}</inlineCode>{` are monetary values of the lowest possible denominator for the reservation location.  For example, if the reservation is in the United States, these are in US pennies.  If the reservation is in Canada, these are in Canadian pennies.`}</p>
          </li>
        </ul>
      </DSM.Row>
    </DSM.Callout>
    <h2>{`Failed Webhook Requests Email`}</h2>
    <p>{`Sometimes these webhook requests fail and your reservation records might become out of sync with SpotHero.  In order to accommodate this scenario, SpotHero keeps track of all reservation updates that did not successfully call your webhook and sends a nightly email containing each of the reservation IDs for those failed requests in a CSV attachment.`}</p>
    <p>{`If needed, the information for these reservations can be looked up by calling our Reservations API with the corresponding reservation IDs.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;