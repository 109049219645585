import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {withVersion} from 'contexts/version';

import {Link, Callout} from 'components';
import routes from './RouteCallouts.data';

import styles from './RouteCallouts.module.scss';

const RouteCallouts = ({
    location: {pathname},
    versions,
    version,
    setVersion,
}) => {
    const filter = route =>
        `/${route.version}${route.path}` === pathname ||
        (route.path === pathname && route.version === version.slug);

    const latest = versions[0];
    const outdated = routes.outdated.filter(filter);
    const deprecated = routes.deprecated.filter(filter);
    const routeVersion = route => route.version.replace('v', '');

    return (
        <div className={styles.wrapper}>
            {outdated.map(route => (
                <Callout
                    key={route.name}
                    label="Update:"
                    type="info"
                    withIcon
                    className={styles.callout}
                >
                    The {route.name} has been upgraded to version{' '}
                    {latest.display}.{' '}
                    <Link
                        to={['/', latest.slug].join('')}
                        onClick={() => {
                            setVersion({
                                ...latest,
                                isNewlySelected: true,
                            });
                        }}
                    >
                        View Details
                    </Link>
                </Callout>
            ))}
            {deprecated.map(route => (
                <Callout
                    key={route.name}
                    label="Important:"
                    type="important"
                    withIcon
                    className={styles.callout}
                >
                    Version {routeVersion(route)} will be deprecated on{' '}
                    <strong>{route.date}</strong>. Please integrate with version{' '}
                    {latest.display}
                </Callout>
            ))}
        </div>
    );
};

RouteCallouts.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    versions: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string,
            display: PropTypes.string,
        })
    ),
    version: PropTypes.shape({
        slug: PropTypes.string,
    }),
    setVersion: PropTypes.func,
};

export default withVersion(withRouter(RouteCallouts));
