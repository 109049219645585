import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import {CopyEndpoint, ParameterTable, SampleRequest, Schema} from 'components';
import styles from './Swagger2.module.scss';

const Swagger2 = ({docs}) => {
    const getItem = (
        id,
        {depth, withMethod, component, method, name, ...props}
    ) => ({
        component,
        depth,
        exact: true,
        hash: id,
        method,
        name: name || startCase(id.split('_').pop()),
        ...props,
    });

    return flattenDeep([
        getItem('overview', {
            depth: 1,
            component: (
                <p className="body" key={`${docs.info.title}`}>
                    {docs.info.description}
                </p>
            ),
            hasChildren: true,
            name: docs.info.title,
        }),
        ...Object.keys(docs.paths).map(path =>
            Object.keys(docs.paths[path]).map(method => {
                const {
                    operationId,
                    summary,
                    description,
                    parameters,
                    responses,
                } = get(docs, ['paths', path, method], {});
                const endpoint = camelCase(operationId || summary);

                return [
                    getItem(endpoint, {
                        depth: 2,
                        method,
                        component: (
                            <CopyEndpoint
                                key={`${endpoint}_CopyEndpoint`}
                                method={method.toUpperCase()}
                                path={path}
                            />
                        ),
                    }),
                    description &&
                        getItem(`${endpoint}_overview`, {
                            depth: 3,
                            component: (
                                <p
                                    className="body"
                                    key={`${endpoint}_overview`}
                                >
                                    {description}
                                </p>
                            ),
                        }),
                    parameters &&
                        getItem(`${endpoint}_parameters`, {
                            depth: 3,
                            component: (
                                <ParameterTable
                                    data={parameters.map(item => ({
                                        dataType: item.in,
                                        description: item.description,
                                        example: item.example,
                                        name: item.name,
                                        required: item.required,
                                        type: item.type,
                                    }))}
                                    key={`${endpoint}_parameters`}
                                />
                            ),
                        }),
                    getItem(`${endpoint}_exampleRequest`, {
                        depth: 3,
                        component: (
                            <SampleRequest
                                key={`${endpoint}_SampleRequest`}
                                endpoint={path}
                                method={method.toUpperCase()}
                                docs={docs.paths[path][method]}
                            />
                        ),
                    }),
                    ...Object.keys(responses).map(statusCode => {
                        const response = responses[statusCode];
                        const $ref =
                            get(response, 'schema.$ref') ||
                            get(
                                response,
                                'content.application/json.schema.$ref'
                            );

                        return getItem(`${endpoint}_${statusCode}response`, {
                            depth: 3,
                            component: (
                                <Fragment
                                    key={`${endpoint}_${statusCode}response`}
                                >
                                    <p className="body">
                                        {response.description}
                                    </p>
                                    <Schema
                                        className={styles.schema}
                                        definitions={
                                            docs.definitions ||
                                            get(docs, 'components.schemas')
                                        }
                                        startingRef={$ref}
                                        format="example"
                                    />
                                </Fragment>
                            ),
                        });
                    }),
                ].filter(Boolean);
            })
        ),
    ]);
};

Swagger2.propTypes = {
    docs: PropTypes.object,
};

export default Swagger2;
