export const API_URL = 'https://api.spothero.com';
export const SPOTHERO = 'SpotHero';
export const PLATFORM_TITLE = 'SpotHero Mobility Platform';
export const BASE_URL = 'https://developers.spothero.com';
export const SPOTHERO_URL = 'https://spothero.com';
export const SUPPORTED_METHODS = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};
