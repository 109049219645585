import {useEffect, useState} from 'react';

export default function useToast() {
    const [displayToast, setDisplayToast] = useState(false);

    useEffect(() => {
        if (displayToast) {
            setTimeout(() => setDisplayToast(false), 750);
        }
    }, [displayToast]);

    return [displayToast, setDisplayToast];
}
