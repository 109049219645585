import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

const SidebarContext = React.createContext({
    isOpen: false,
});

export const SidebarContextProvider = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

    return (
        <SidebarContext.Provider
            value={{
                isOpen,
                onToggle,
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

SidebarContextProvider.propTypes = {
    children: PropTypes.node,
};

export default SidebarContext;
