
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import DSM from 'components/dsm';
export const meta = {
  title: 'Affiliate Links',
  content: 'Our most light-weight solution: add affiliate links to your product.',
  buttonTitle: 'View Links Docs'
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Affiliate Links`}</h1>
    <p>{`Take advantage of SpotHero's extensive market research to ensure the best conversion rates at checkout.`}</p>
    <p>{`Affiliate links are the easiest way to supply parking to your users. An `}<strong parentName="p">{`affiliate link`}</strong>{` is a referral URL that can send traffic from anywhere to SpotHero’s app or website for users to complete checkout.`}</p>
    <DSM.Callout type="info">
      <DSM.Row>
        <DSM.Column>
          <ul>
            <li parentName="ul">{`SpotHero will track referrals from the affiliate`}</li>
            <li parentName="ul">{`Users complete checkout within the SpotHero UI`}</li>
          </ul>
        </DSM.Column>
        <DSM.Column>
          <ul>
            <li parentName="ul">{`SpotHero is Merchant of Record`}</li>
            <li parentName="ul">{`SpotHero is responsible for data security and PCI compliance`}</li>
          </ul>
        </DSM.Column>
      </DSM.Row>
    </DSM.Callout>
    <h2>{`Step 1: Create An Account`}</h2>
    <p>{`In order to use affiliate links, you must first `}<a parentName="p" {...{
        "href": "https://spothero.com/user/new"
      }}>{`create an account`}</a>{`. This account will receive important email communications from SpotHero, such as cancellations and refunds on your referred reservations.`}</p>
    <h2>{`Step 2: Register As An Affiliate`}</h2>
    <p><a parentName="p" {...{
        "href": "mailto:partner.support@spothero.com?subject=Parking%20Mobility%20Platform%20%7C%20Register%20as%20an%20Affiliate&body=Name:%0D%0ACompany:%0D%0ATitle:%0D%0ACorporate%20Email:%0D%0AAnticipated%20Number%20of%20Reservations%20per%20Month:%0D%0ASpotHero%20Account%20Email:"
      }}>{`Let us know`}</a>{` when your account is ready so our team can set you up as a registered affiliate. Account details will be discussed at this time.`}</p>
    <p>{`We'll reply with your affiliate information. Affiliates will be given a unique `}<strong parentName="p">{`affiliate code`}</strong>{` (e.g. `}<inlineCode parentName="p">{`goodaffiliate`}</inlineCode>{`; used for generating referral URLs) and `}<strong parentName="p">{`affiliate ID`}</strong>{` (e.g. `}<inlineCode parentName="p">{`1235`}</inlineCode>{`; used for tracking).`}</p>
    <DSM.Callout label="Important:" type="warning" withIcon>
    You won't get credit for your referrals until this step is complete.
    </DSM.Callout>
    <h2>{`Step 3: Build Affiliate Links`}</h2>
    <p>{`The affliate refers traffic to SpotHero with the affiliate code in the referral URL. Affiliates can send referral traffic to any page on SpotHero.`}</p>
    <p>{`Depending on the affiliate's needs, we provide several `}<strong parentName="p">{`integration options`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Web Widget:`}</strong>{` embed HTML to display dynamic inventory customized to your time, location, or event`}</li>
      <li parentName="ul"><strong parentName="li">{`iOS SDK:`}</strong>{` show dynamic inventory with a streamlined UI inside your iOS App`}</li>
      <li parentName="ul"><strong parentName="li">{`CSV:`}</strong>{` receive a list of affiliate links for a customized set of static inventory`}</li>
      <li parentName="ul"><strong parentName="li">{`API:`}</strong>{` query dynamic inventory for your needs and build affiliate links according to our URL schemas`}</li>
    </ul>
    <p>{`Or, to create an affiliate link manually, append your assigned affiliate code to your desired referral URLs.`}</p>
    <h3>{`Select a Landing page`}</h3>
    <p>{`SpotHero supports a variety of landing pages depending on the experience you would like to provide. Our team will be happy to work with you to identify the right landing pages for your flow.`}</p>
    <DSM.LandingPages />
    <h3>{`Customize Your referral`}</h3>
    <p>{`You can further customize the referral experience by including supported query parameters. Modify the table below to see how query parameters auto-populate the selected landing page.`}</p>
    <DSM.QueryParameters />
    <h2>{`Step 4: Publish Your Links`}</h2>
    <p>{`When a user clicks an affiliate link, they are taken to SpotHero where the affiliate code is stored as a cookie. This begins a referral session. The cookie ensures that a user who is referred to SpotHero through an affiliate maintains that association even if they open new tabs, navigate around the app, or leave and return later.`}</p>
    <p>{`Reservation transactions that are completed during an active referral session will be tagged with the affiliate ID for generating reports.`}</p>
    <DSM.AdditionalSupport />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;