import React, {useState, useEffect} from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styles from './AnchorSidebar.module.scss';
import kebabCase from 'lodash/kebabCase';
import classNames from 'classnames';
import {depthClassName} from 'utils/format';
import {
    Menu,
    Route,
    ScrollContainer,
    Sidebar,
    Switch,
    NavLink,
    RouteCallouts,
} from 'components';
import {
    APIOverview,
    Authentication,
    Guides,
    Markdown,
    NotFound,
    Overview,
    Reference,
    Scenarios,
    GetStarted,
    SdkOverview,
    Reports,
    Support,
    Account,
    Root,
    ReleaseNotes,
} from 'pages';
import routes from 'routes';
import {withVersion} from 'contexts/version';

export const AnchorSidebar = ({menu, version, location: {pathname, hash}}) => {
    const selected = (
        menu.find(
            ({routes: menuRoutes = []}) =>
                menuRoutes.filter(route => pathname.includes(route)).length
        ) || {}
    ).title;
    const [selection, setSelection] = useState(selected);

    useEffect(() => {
        const paths = pathname.split('/').filter(Boolean);

        // when the location changes to ONLY version in path
        // close up the nav
        if (paths.length === 1 && paths.includes(version.slug)) {
            setSelection(false);
        }
    }, [pathname, version.slug]);

    return (
        <section className={styles.AnchorSidebar}>
            <Sidebar>
                {/* those with sub menus */}
                {menu
                    .filter(submenu => get(submenu, 'menu', []).length)
                    .filter(submenu =>
                        selection ? selection === submenu.title : true
                    )
                    .map(({title, icon, menu: submenu}) => (
                        <Menu
                            key={title}
                            items={submenu}
                            title={title}
                            icon={icon}
                            selection={pathname + hash}
                            onOpen={() => setSelection(title)}
                            onClose={() => setSelection(false)}
                            isOpen={selection === title}
                        />
                    ))}
                {/* those without sub menus, aka root level pages */}
                {!selection &&
                    menu
                        .filter(submenu => !submenu.menu)
                        .map(({name, path, depth}) => (
                            <NavLink
                                key={name}
                                to={`/${path}`}
                                versionLess
                                className={classNames(
                                    styles.root,
                                    styles[depthClassName(depth)]
                                )}
                                activeClassName={styles.active}
                            >
                                {name}
                            </NavLink>
                        ))}
            </Sidebar>
            <ScrollContainer className={styles.scrollContainer} key={pathname}>
                <RouteCallouts />
                <Switch>
                    <Route
                        component={Overview}
                        path={[routes.version, routes.home].join('')}
                        exact
                    />
                    <Route
                        component={APIOverview}
                        path={[routes.version, routes.reference.overview].join(
                            ''
                        )}
                        exact
                    />
                    <Route
                        component={Authentication}
                        path={[
                            routes.version,
                            routes.reference.authentication,
                        ].join('')}
                        exact
                    />
                    <Route
                        path={[routes.version, routes.sdk.overview].join('')}
                        component={SdkOverview}
                        exact
                    />

                    {Object.keys(GetStarted || {})
                        .filter(
                            name => typeof get(GetStarted, name) === 'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.getStarted, kebabCase(name)].join(
                                        '/'
                                    ),
                                ].join('')}
                                component={Markdown(GetStarted[name])}
                                exact
                            />
                        ))}

                    {Object.keys(Account || {})
                        .filter(
                            name => typeof get(Account, name) === 'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.account, kebabCase(name)].join('/'),
                                ].join('')}
                                component={Markdown(Account[name])}
                                exact
                            />
                        ))}
                    {Object.keys(Support || {})
                        .filter(
                            name => typeof get(Support, name) === 'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.support, kebabCase(name)].join('/'),
                                ].join('')}
                                component={Markdown(Support[name])}
                                exact
                            />
                        ))}
                    {Object.keys(ReleaseNotes || {})
                        .filter(
                            name =>
                                typeof get(ReleaseNotes, name) === 'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.releaseNotes, kebabCase(name)].join(
                                        '/'
                                    ),
                                ].join('')}
                                component={Markdown(ReleaseNotes[name])}
                                exact
                            />
                        ))}
                    {Object.keys(Reports || {})
                        .filter(
                            name => typeof get(Reports, name) === 'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.reports, kebabCase(name)].join('/'),
                                ].join('')}
                                component={Markdown(Reports[name])}
                                exact
                            />
                        ))}
                    {Object.keys(get(Scenarios, version.slug, []))
                        .filter(
                            name =>
                                typeof get(Scenarios, [version.slug, name]) ===
                                'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.scenarios, kebabCase(name)].join(
                                        '/'
                                    ),
                                ].join('')}
                                component={Markdown(
                                    Scenarios[version.slug][name]
                                )}
                                exact
                            />
                        ))}
                    {Object.keys(get(Guides, version.slug, []))
                        .filter(
                            name =>
                                typeof get(Guides, [version.slug, name]) ===
                                'function'
                        )
                        .map(name => (
                            <Route
                                key={name}
                                path={[
                                    routes.version,
                                    [routes.guides, kebabCase(name)].join('/'),
                                ].join('')}
                                component={Markdown(Guides[version.slug][name])}
                                exact
                            />
                        ))}

                    {Object.keys(Root || {})
                        .filter(name => typeof get(Root, name) === 'function')
                        .map(name => (
                            <Route
                                key={name}
                                path={`/${kebabCase(name)}`}
                                component={Markdown(Root[name])}
                                exact
                            />
                        ))}
                    <Route
                        path={[
                            routes.version,
                            [routes.reference.overview, ':docs'].join('/'),
                        ].join('')}
                        render={Reference}
                    />
                    <Route component={NotFound} />
                </Switch>
            </ScrollContainer>
        </section>
    );
};

AnchorSidebar.defaultProps = {
    menu: [],
};

AnchorSidebar.propTypes = {
    version: PropTypes.object,
    location: PropTypes.object,
    menu: PropTypes.array,
};

export default withVersion(AnchorSidebar);
