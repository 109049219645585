
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import DSM from 'components/dsm';
import data from './CheckInventory.data.js';
export const meta = {
  title: 'Check Inventory',
  content: 'Search for parking lots/garages and display price.',
  icon: 'search',
  onOverview: true,
  sort: 1
};

const layoutProps = {
  meta
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Check Inventory`}</h1>
    <p>{`In this quick scenario, we'll cover the necessary steps to get your users looking for parking near an event in SoMa, San Francisco. You'd like to present a list of parking facilities (open lots, garages, etc.) to your user based on their destination, arrival time, and price.`}</p>
    <p>{`If not `}<a parentName="p" {...{
        "href": "/reference/authentication"
      }}>{`authenticated`}</a>{`, do so first! If you've already done this, you rock.`}</p>
    <h2>{`Step 1: Retrieve Latitude and Longitude Points`}</h2>
    <p>{`Collect the lat/long of the user's destination to use with the `}<inlineCode parentName="p">{`/v1/search`}</inlineCode>{` endpoint. For example, you can use the `}<a parentName="p" {...{
        "href": "https://developers.google.com/places/web-service/search?utm_source=google&utm_medium=cpc&utm_campaign=FY18-Q2-global-demandgen-paidsearchonnetworkhouseads-cs-maps_contactsal_saf&utm_content=text-ad-none-none-DEV_c-CRE_315916117883-ADGP_Hybrid+%7C+AW+SEM+%7C+BKWS+~+Google+Maps+Place+Search-KWID_43700039136946261-kwd-582432942155-userloc_9060252&utm_term=KW_%2Bgoogle%20%2Bplace%20%2Bsearch-ST_%2Bgoogle+%2Bplace+%2Bsearch&gclid=CNu65cDQlOMCFVOOxQId4acFEA"
      }}>{`Google Places API`}</a>{` to get the lat/long of a given address. In the example, we'll be searching for parking near `}<strong parentName="p">{`505 7th St. San Francisco, CA 94103`}</strong>{`.`}</p>
    <DSM.CopyEndpoint {...data.CopyEndpoint} />
    <DSM.ParameterTable {...data.ParameterTable} />
    <h2>{`Step 2: Sample Request`}</h2>
    <p>{`Here's an example request using the above calls:`}</p>
    <DSM.Snippet language="curl" name="checkInventoryRequest" />
    <h2>{`Step 3: Sample Response`}</h2>
    <p>{`Here's an example response. But first, there are a few important things to note:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`rule_group_id`}</inlineCode>{` is the rate band that a user would pay if they park during a certain time. As you may be aware, rate bands can be unique and don't often follow hourly rates. For example, there may be a "commuter" rate band from 7:00AM-6:00PM, and there may also be a rate band from 8:00AM-12:00PM. SpotHero automatically extends a user's reservations to the best priced rate band (the user's start and end time may not match search values).`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`price`}</inlineCode>{` for each facility is for that `}<inlineCode parentName="li">{`rule_group_id`}</inlineCode>{`, for the duration of time, and is returned in pennies (1 = $0.01).`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`amenities`}</inlineCode>{` are returned for each facility.`}</li>
    </ol>
    <DSM.Snippet name="checkInventoryResponse" />
    <DSM.Callout type="info" withIcon>
      <p><inlineCode parentName="p">{`facilities[n].id === facility_id`}</inlineCode>{` on the `}<a parentName="p" {...{
          "href": "/reference/reservations#createReservation_createReservation"
        }}>{`Create Reservation`}</a>{` endpoint.`}</p>
    </DSM.Callout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;