import React from 'react';
import PropTypes from 'prop-types';
import styles from './Scenario.module.scss';
import classNames from 'classnames';
import {Card, Icon} from 'components';

const Scenario = ({children, content, className, icon, onClick, title}) => {
    const body = content || children;

    return (
        <Card
            className={classNames(styles.Scenario, className)}
            key={title}
            icon={icon}
            onClick={onClick}
            title={title}
        >
            {Boolean(icon) && (
                <div className={styles.iconContainer}>
                    <Icon className={styles.icon} name={icon} size={40} />
                </div>
            )}
            <div className={styles.body}>
                {title && <p className={styles.title}>{title}</p>}
                {body && <p className="copy">{body}</p>}
            </div>
        </Card>
    );
};

Scenario.propTypes = {
    /** Alternative way to pass in content */
    children: PropTypes.any,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** MDX-compatible way to pass in content */
    content: PropTypes.string,
    /** Whether or not to display an icon */
    icon: PropTypes.string,
    /** Text title to display at top of Card */
    title: PropTypes.string,
    /** Adds a click interaction to the entire card */
    onClick: PropTypes.func,
};

export default Scenario;
