import React from 'react';
import PropTypes from 'prop-types';
import styles from './LineBreak.module.scss';
import classNames from 'classnames';

const LineBreak = ({children, className}) => {
    return (
        <div className={classNames(styles.LineBreak, className)}>
            {children}
        </div>
    );
};

LineBreak.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default LineBreak;
