import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import classNames from 'classnames';
import {useSwipe} from 'hooks';

const Table = ({headers, data, className, onRowClick}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [tableWidth, setTableWidth] = useState(0);
    const {handleEndMove, handleStartMove} = useSwipe(onSwipe);
    const element = useRef(null);

    useEffect(() => {
        if (element && element.current) {
            setTableWidth(element.current.getBoundingClientRect().width);
        }
    }, [element]);

    function handleSelectIndex(evt, headerIndex) {
        evt.target.parentElement.scrollLeft =
            evt.target.getBoundingClientRect().left - 24;
        setSelectedIndex(headerIndex);
    }

    function onSwipe(swipe) {
        if (swipe.left && selectedIndex < headers.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        } else if (swipe.right && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    }

    if (!data || !data.length || !data.filter(Boolean).length) return null;

    return (
        <div className={classNames(styles.Table, className)} ref={element}>
            {Boolean(headers && headers.length) && (
                <ul className={classNames(styles.header, styles.tabs)}>
                    {headers.map((header, headerIndex) => (
                        <li
                            className={classNames(styles.tab, styles.heading, {
                                [styles.selected]:
                                    selectedIndex === headerIndex,
                            })}
                            key={headerIndex}
                            onClick={evt => handleSelectIndex(evt, headerIndex)}
                        >
                            {header}
                        </li>
                    ))}
                </ul>
            )}
            {Boolean(data && data.length) && (
                <table
                    className={styles.body}
                    style={{
                        marginLeft: tableWidth * -selectedIndex,
                    }}
                >
                    {Boolean(headers && headers.length) && (
                        <thead
                            className={classNames(
                                styles.header,
                                styles.columns
                            )}
                        >
                            <tr>
                                {headers.map((header, headerIndex) => (
                                    <th
                                        className={classNames(
                                            styles.column,
                                            styles.heading,
                                            {
                                                [styles.selected]:
                                                    selectedIndex ===
                                                    headerIndex,
                                            }
                                        )}
                                        key={headerIndex}
                                        onClick={evt =>
                                            handleSelectIndex(evt, headerIndex)
                                        }
                                    >
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    <tbody
                        onTouchStart={handleStartMove}
                        onTouchEnd={handleEndMove}
                    >
                        {data.map((row, rowIndex) => (
                            <tr
                                className={styles.row}
                                key={rowIndex}
                                {...(onRowClick && {
                                    onClick: () => onRowClick(rowIndex),
                                })}
                            >
                                {row.map((item, itemIndex) => (
                                    <td
                                        className={classNames(styles.cell, {
                                            [styles.selected]:
                                                selectedIndex === itemIndex,
                                            [item.className]: Boolean(
                                                item.className
                                            ),
                                        })}
                                        key={itemIndex}
                                        style={{
                                            width: tableWidth,
                                        }}
                                    >
                                        {typeof item === 'function'
                                            ? item()
                                            : item.component
                                            ? item.component()
                                            : item.name || item}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

Table.defaultProps = {
    data: [],
    headers: [],
};

Table.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
                PropTypes.shape({
                    component: PropTypes.func,
                    className: PropTypes.string,
                    name: PropTypes.string,
                }),
            ])
        )
    ),
    headers: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    ),
    className: PropTypes.string,
    onRowClick: PropTypes.func,
};

export default Table;
