export default {
    '401 unauthorized': `curl -sv -X GET https://api.spothero.com/v1/search

...

> GET /v1/search HTTP/2
> Host: api.spothero.com
> User-Agent: curl/7.54.0
> Accept: */*
>
* Connection state changed (MAX_CONCURRENT_STREAMS updated)!
< HTTP/2 401
< date: Thu, 30 May 2019 16:00:25 GMT
< content-type: application/json
< content-length: 23
<

{"message": "Unauthorized"}
`,

    'client credentials grant': `curl -s -X POST \\
    -H "Authorization: Basic BASE64($CLIENT_ID:$CLIENT_SECRET)"  \\
    -H "Content-Type: application/x-www-form-urlencoded" \\
    https://auth.spothero.com/oauth2/token?grant_type=client_credentials


{
    "access_token": "arcihasiutnhaustnhau393923huihaoeu93923h1288384",
    "token_type": "bearer",
    "expires_in": 588392092,
}`,

    'authorized request and response': `curl -sv -X GET \\
    -H "Authorization: Bearer $ACCESS_TOKEN" \\
    https://api.spothero.com/v1/search


{
    ...
}`,

    '403 http status response': `curl -sv -X GET \\
    -H "Authorization: Bearer $ACCESS_TOKEN" \\
    https://api.spothero.com/v1/search


...


> GET /v1/search HTTP/2
> Host: api.spothero.com
> User-Agent: curl/7.54.0
> Accept: */*
>
* Connection state changed (MAX_CONCURRENT_STREAMS updated)!
< HTTP/2 403
< date: Thu, 30 May 2019 16:00:25 GMT
< content-type: application/json
< content-length: 23
<


{"message": "Forbidden"}
,
}`,
};
