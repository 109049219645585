import React, {createRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import {Icon, Link, NavLink} from 'components';
import {depthClassName} from 'utils/format';
import styles from './MenuItem.module.scss';

function getElement(selection, {depth, path}) {
    if (!path) {
        return props => <p {...props} />;
    }

    if (path.split('#')[0] === selection.split('#')[0]) {
        return props => <Link forceAnchor to={path} {...props} />;
    }

    return props => <NavLink to={path} {...props} />;
}

export function getIsDisplayed({depth, selection = '', path = ''}) {
    // /:category/:page#:section_:subsection
    const is = {
        category: () =>
            path.split('#')[0].split('/')[1] ===
            selection.split('#')[0].split('/')[1],
        page: () => path.split('#')[0] === selection.split('#')[0],
        section: () => path.split('_')[0] === selection.split('_')[0],
    };

    switch (depth) {
        case 1:
            // /reference#Available%20APIs => /reference
            // /reference/authentication#overview => /reference
            return is.category();
        case 2:
            // /reference/facilities#getByFacilityId => /reference/facilities
            return is.page();
        case 3:
            // /reference/search#getByLocation_exampleRequest => /reference/search#getByLocation
            // /integration/page#section_subsection => section
            return is.section();
        case 0: // fallthrough
            return true;
        default:
            return false;
    }
}

const MenuItem = ({
    className,
    depth,
    exact,
    hasChildren,
    method,
    name,
    icon,
    path = '',
    readOnly,
    selection,
    versionLess,
}) => {
    const ref = createRef();
    const isDisplayed = getIsDisplayed({depth, selection, path});
    const isSelected =
        !readOnly &&
        (exact ? path === selection : selection.split('#')[0] === path);
    const Element = getElement(selection, {depth, path});

    useEffect(() => {
        if (ref && ref.current && isSelected) {
            const parent =
                ref.current.parentElement.parentElement.parentElement;
            const isAboveFold = ref.current.offsetTop < parent.scrollTop;
            const isBelowFold =
                ref.current.offsetTop + ref.current.offsetHeight >
                parent.offsetHeight + parent.scrollTop;

            if (isAboveFold || isBelowFold) {
                ref.current.scrollIntoView(true);
                parent.scrollTop -= ref.current.offsetHeight;
            }
        }
    }, [ref, isSelected]);

    return (
        <li
            className={classNames(
                styles.MenuItem,
                {[styles.hidden]: !isDisplayed},
                className
            )}
            key={path}
            ref={ref}
        >
            <Element
                className={classNames(
                    {[styles[depthClassName(depth)]]: isNumber(depth)},
                    styles.item,
                    {[styles.link]: Boolean(path)},
                    {[styles.selected]: isSelected}
                )}
                versionLess={versionLess}
            >
                {icon && <Icon className={styles.icon} name={icon} size={24} />}
                {method && (
                    <span className={classNames(styles.method, styles[method])}>
                        {method}
                    </span>
                )}
                {name}
                {depth === 1 && hasChildren && (
                    <Icon
                        className={classNames(
                            styles.toggle,
                            styles[getIsDisplayed({depth: 2, selection, path})]
                        )}
                        name="arrow"
                        size={16}
                    />
                )}
            </Element>
        </li>
    );
};

MenuItem.defaultProps = {
    selection: '',
};

MenuItem.propTypes = {
    className: PropTypes.string,
    depth: PropTypes.oneOf([0, 1, 2, 3]),
    icon: PropTypes.string,
    exact: PropTypes.bool,
    hasChildren: PropTypes.bool,
    method: PropTypes.string,
    name: PropTypes.node,
    readOnly: PropTypes.bool,
    selection: PropTypes.string,
    path: PropTypes.string,
    versionLess: PropTypes.bool,
};

export default MenuItem;
