import React, {useCallback} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './CopyEndpoint.module.scss';
import {API_URL, SUPPORTED_METHODS} from 'lang';
import {CopyTarget, Toast} from 'components';
import {useCopyText, useToast} from 'hooks';

const CopyEndpoint = ({path, method, className}) => {
    const copyValue = `${API_URL}${path || ''}`;
    const [displayToast, setDisplayToast] = useToast();
    const toggleToast = useCallback(() => setDisplayToast(true), [
        setDisplayToast,
    ]);
    const {copyTargetRef, copyFocusRef, copyText} = useCopyText(toggleToast);

    return (
        <div className={styles.CopyEndpoint}>
            <div
                className={classNames(styles.button, className)}
                onClick={copyText}
            >
                <div
                    className={classNames(
                        styles.method,
                        styles[method.toLowerCase()]
                    )}
                >
                    {method.toUpperCase()}
                </div>
                <div className={styles.path}>
                    <div className={styles.container}>
                        {path.split('/').map(
                            (chunk, index) =>
                                chunk && (
                                    <span
                                        className={styles.segment}
                                        key={chunk}
                                    >
                                        {`/${chunk}`}
                                    </span>
                                )
                        )}
                    </div>
                </div>
                <div className={styles.copy} ref={copyFocusRef}>
                    Copy
                    <Toast
                        className={styles.toast}
                        displayToast={displayToast}
                    />
                </div>
            </div>
            <CopyTarget
                className={styles.copyTarget}
                ref={copyTargetRef}
                value={copyValue}
                readOnly
            />
        </div>
    );
};

CopyEndpoint.defaultProps = {
    method: SUPPORTED_METHODS.GET,
    path: '/',
};

CopyEndpoint.propTypes = {
    className: PropTypes.string,
    method: PropTypes.oneOf(Object.keys(SUPPORTED_METHODS)).isRequired,
    path: PropTypes.string.isRequired,
};

export default CopyEndpoint;
