import React from 'react';
import PropTypes from 'prop-types';
import styles from './Integration.module.scss';
import classNames from 'classnames';
import {Card, Link, NavLink} from 'components';

const NavElement = process.env.NO_ROUTER ? Link : NavLink;

const Integration = ({
    children,
    content,
    buttonTitle,
    className,
    title,
    url,
}) => {
    const body = content || children;

    return (
        <div className={classNames(styles.Integration, className)} key={url}>
            <Card>
                <div className={styles.flexContainer}>
                    {title && <p className={styles.title}>{title}</p>}
                    {body && (
                        <div className={styles.body}>
                            <p className="copy">{body}</p>
                        </div>
                    )}
                    {buttonTitle && url && (
                        <NavElement className={styles.button} to={url}>
                            {buttonTitle}
                        </NavElement>
                    )}
                </div>
            </Card>
        </div>
    );
};

Integration.propTypes = {
    /** Button title; requires URL */
    buttonTitle: PropTypes.string,
    /** Alternative way to pass content to card */
    children: PropTypes.any,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** MDX-compatible way to pass content to card */
    content: PropTypes.string,
    /** Text content to display at top of Card */
    title: PropTypes.string,
    /** Path to navigate to on button click; requires buttonTitle */
    url: PropTypes.string,
};

export default Integration;
