/**
 * Returns a formatted date in mm/dd/yyyy format (i.e. no leading 0)
 * @example mmddyyyy() => 8/8/2019
 * @param {Date} date - Javascript Date object
 * @returns {String} mm/dd/yyyy formatted date with no leading 0
 */
export default function mmddyyyy(date = new Date(Date.now())) {
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    const yyyy = date.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
}
