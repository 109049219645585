import React from 'react';
import PropTypes from 'prop-types';
import styles from './LandingPages.module.scss';
import classNames from 'classnames';
import {Link, Table} from 'components';
import referralURL from 'utils/format/referralURL';
import pageTypes from 'utils/data/pageTypes';

const LandingPages = ({className, onRowClick, schemaId}) => {
    return (
        <div className={classNames(styles.LandingPages, className)}>
            <Table
                className={classNames(styles.table, styles.pageTypes)}
                headers={['Description', 'Schema']}
                data={pageTypes.list.map(id => {
                    const {
                        description,
                        name,
                        parameters,
                        path,
                    } = pageTypes.byID[id];
                    const url = referralURL.getBySchemaID(id);

                    return [
                        {
                            component: () => (
                                <div
                                    className={classNames({
                                        [styles.selected]: schemaId === id,
                                    })}
                                    key="name"
                                >
                                    <p className={styles.name}>{name}</p>
                                    <div>{description}</div>
                                </div>
                            ),
                            className: styles.item,
                        },
                        {
                            component: () => (
                                <div
                                    className={classNames(styles.schema, {
                                        [styles.selected]: schemaId === id,
                                    })}
                                    key="path"
                                >
                                    <p className={styles.name}>{name}</p>
                                    <p
                                        className={classNames(
                                            'copy',
                                            styles.path
                                        )}
                                    >
                                        <span
                                            className={styles.segment}
                                            key="path"
                                        >{`${path}`}</span>
                                        {parameters.map(term => (
                                            <span
                                                className={styles.segment}
                                                key={term}
                                            >
                                                <span>/</span>
                                                <code className={styles.term}>
                                                    {term}
                                                </code>
                                            </span>
                                        ))}
                                    </p>
                                    <p className={styles.example}>
                                        Example:{' '}
                                        <Link to={url}>
                                            {url.split('.com')[1]}
                                        </Link>
                                    </p>
                                </div>
                            ),
                            className: styles.item,
                        },
                    ];
                })}
                onRowClick={onRowClick}
            />
        </div>
    );
};

LandingPages.propTypes = {
    className: PropTypes.string,
    schemaId: PropTypes.string,
    onRowClick: PropTypes.func,
};

export default LandingPages;
