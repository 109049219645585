export default {
    authorizationHeader: `
    curl -H 'x-api-token: xw6Rer4CY34A80S75O56CH1XjZkQtRLoTDk4RFzy' \\
        https://api.spothero.com/v2/search
    `,
    unauthorizedError: `
    curl -sv -X GET https://api.spothero.com/v1/search
    \\n
    ...
    \\n
    > GET /v1/search HTTP/2
    > Host: api.spothero.com
    > User-Agent: curl/7.54.0
    > Accept: */*
    >
    * Connection state changed (MAX_CONCURRENT_STREAMS updated)!
    < HTTP/2 401
    < date: Thu, 30 May 2019 16:00:25 GMT
    < content-type: application/json
    < content-length: 23
    <
    \\n
    {"message": "Unauthorized"}
    `,
    clientCredentials: `
    curl -s -X POST \\
        -H "Authorization: Basic BASE64($CLIENT_ID:$CLIENT_SECRET)"  \\
        -H "Content-Type: application/x-www-form-urlencoded" \\
        https://auth.spothero.com/oauth2/token?grant_type=client_credentials
    \\n
    {
        "access_token": "arcihasiutnhaustnhau393923huihaoeu93923h1288384",
        "token_type": "bearer",
        "expires_in": 588392092,
    }
    `,
    bearerToken: `
    curl -sv -X GET \\
        -H "Authorization: Bearer $ACCESS_TOKEN" \\
        https://api.spothero.com/v1/search
    \\n
    {
        ...
    }
    `,
    forbiddenError: `
    curl -sv -X GET \\
        -H "Authorization: Bearer $ACCESS_TOKEN" \\
        https://api.spothero.com/v1/search
    \\n
    ...
    \\n
    > GET /v1/search HTTP/2
    > Host: api.spothero.com
    > User-Agent: curl/7.54.0
    > Accept: */*
    >
    * Connection state changed (MAX_CONCURRENT_STREAMS updated)!
    < HTTP/2 403
    < date: Thu, 30 May 2019 16:00:25 GMT
    < content-type: application/json
    < content-length: 23
    <
    \\n
    {"message": "Forbidden"}
    `,
    facilityDetailsMetaData: `
        {
            "notifications": [],
            "meta": {
                "count": 20,
                "next": "http://api.spothero.com/facilities/rates/?ends=2019-08-19T11%3A00&starts=2019-08-18T11%3A00&distance__gt=366&longitude=-87.63240294449997&latitude=41.88214784288587",
            },
            "data": {}
        }`,
    facilityDetailsResponse: `
        {
            "notifications": [],
            "meta": {},
            "data": {
                "hourly_rates": [
                    {
                        "rule_type": "multirate",
                        "online_commuter_rate_enter_start": null,
                        "ends": "2019-07-22T10:00",
                        "rule_group_id": 608,
                        "starts": "2019-07-21T10:00",
                        "unavailable": false,
                        "title": "Default",
                        "event_id": null,
                        "display_price": 35,
                        "price": 3500,
                        "unavailable_reason": "",
                        "online_commuter_rate_description": null,
                        "online_commuter_rate": false,
                        "url": "https://spothero.com/chicago-parking/172-w-madison-st-2?start_date=07-21-2019&start_time=1000AM&end_date=07-22-2019&end_time=1000AM",
                        "amenities": {
                            "mobile": {
                                "name": "Mobile Pass Accepted",
                                "slug": "mobile",
                                "visible": true
                            },
                            "wheelchair": {
                                "name": "Accessible",
                                "slug": "wheelchair",
                                "visible": true
                            },
                            "self-park": {
                                "name": "Self Park",
                                "slug": "self-park",
                                "visible": false
                            },
                            "covered-parking": {
                                "name": "Covered",
                                "slug": "covered-parking",
                                "visible": true
                            },
                            "paved": {
                                "name": "Paved",
                                "slug": "paved",
                                "visible": false
                            }
                        },
                        "rule_trail": "6775",
                        "online_commuter_rate_enter_end": null,
                        "rule_id": 6775
                    }
                ],
                "monthly_rates": [],
                "latitude": 41.8819802,
                "spot_url": "https://spothero.com/chicago-parking/172-w-madison-st-2",
                "highest_monthly_price": 0,
                "wd_latitude": null,
                "timezone": "America/Chicago",
                "parking_spot_id": 683,
                "lowest_monthly_price": 0,
                "license_plate_required": false,
                "distance": 0,
                "default_image_url": "https://res.cloudinary.com/spothero/image/upload/c_fill,f_auto,g_xy_center,h_98,q_50,w_145,x_1128,y_752/v1387154380/uuaxi25nncviyfapyyos.jpg",
                "wd_longitude": null,
                "title": "172 W Madison St. - Garage",
                "longitude": -87.63324,
                "phone_number_required": false,
                "spot_notes": [],
                "operator_notes": [],
                "lowest_price": 0,
                "highest_price": 0
            }
        }`,
    genericWrapper: `
        {
            "notifications": [],
            "meta": {},
            "data": {}
        }`,
    invalidToken: `
        {
            "notifications": [],
            "meta": {},
            "data": {
                "errors": [
                    {
                        "code": "",
                        "messages": [
                            "Invalid token"
                        ]
                    }
                ],
                "extra": {}
            }
        }`,
    rateLimited: `
        {
            "notifications": [],
            "meta": {},
            "data": {
                "errors": [
                    {
                        "code": "",
                        "messages": [
                            "Request was throttled.Expected available in 20 seconds."
                        ]
                    }
                ],
                "extra": {}
            }
        }`,
    fieldRequired: `
        {
            "notifications": [],
            "meta": {},
            "data": {
                "errors": [
                    {
                        "messages": [
                            "This field is required."
                        ],
                        "code": "field_required",
                        "field_name": "latitude"
                    }
                ]
            }
        }`,
    facilityMeta: `
        {
            "meta": {
                "next": "http://api.spothero.com/facilities/rates/?ends=2019-08-19T11%3A00&starts=2019-08-18T11%3A00&distance__gt=366&longitude=-87.63240294449997&latitude=41.88214784288587",
                "count": 14
            }
            ...
        }`,
    searchResults: `
        curl -H "Authorization: Token YOURTOKEN"
            "https://api.spothero.com/facilities/rates/?latitude=41.88214784288587&longitude=-87.63240294449997&starts=2019-08-03T14%3A00&ends=2019-08-04T14%3A00&limit=3" | jq '.data.results[0]'
        \\n
        {
            "hourly_rates": [
                {
                    "rule_type": "multirate",
                    "ends": "2019-08-04T14:00",
                    "rule_group_id": 608,
                    "starts": "2019-08-03T14:00",
                    "price": 2500,
                    ...
                }
            ],
            "facility": {
                "id": 683,
                "street_address": "172 W Madison St",
                "city": "Chicago",
                "state": "IL",
                "zipcode": "60602",
                "latitude": 41.8819802,
                "longitude": -87.63324,
                "timezone": "America/Chicago",
                "barcode_type": "qrcode",
                ...
            },
            "spot_url": "https://spothero.com/chicago-parking/172-w-madison-st-2",
            "license_plate_required": false,
            "wd_latitude": 41.8819802,
            "timezone": "America/Chicago",
            "parking_spot_id": 683,
            "lowest_monthly_price": 0,
            "distance": 71,
            "default_image_url": "https://res.cloudinary.com/spothero/image/upload/c_fill,f_auto,g_xy_center,h_98,q_50,w_145,x_1128,y_752/v1387154380/uuaxi25nncviyfapyyos.jpg",
            "wd_longitude": -87.63324,
            "title": "172 W Madison St. - Garage",
            "longitude": -87.63324,
            "phone_number_required": false,
            "latitude": 41.8819802,
            "highest_price": 2500,
            "lowest_price": 2500,
            "highest_monthly_price": 0
        }`,
    reserveRequest: `
        curl -d price=2500
            -d facility_id=683
            -d starts="2019-08-03T14:00"
            -d ends="2019-08-04T14:00"
            -d rule_group_id=608
            -d email="customer@example.com"
            -H "Authorization: Token YOURTOKEN"
            "https://api.spothero.com/reservations/"
        \\n
        {
            "notifications": [],
            "meta": {},
            "data": {
                "display_id": 3616684,
                "cancel_url": "https://api.spothero.com/partner/v1/reservations/3616684/cancel/?key=462465c893158b2256800f13d0ddc2d01410ca19522b1940a648fcab8520359d",
                "ends": "2019-08-04T19:00:00Z",
                "rental_id": 3616684,
                "reservation_status": "valid",
                "starts": "2019-08-03T19:00:00Z",
                "price": 2500,
                "reservation_url": "https://api.spothero.com/partner/v1/reservations/3616684/?key=462465c893158b2256800f13d0ddc2d01410ca19522b1940a648fcab8520359d",
                "license_plate": "",
                "barcode_content": "3616684",
                "facility_id": 683,
                "facility_title": "172 W Madison St. - Garage"
            }
        }`,
    amenities: `
        {
            "amenities": {
                "mobile": {
                    "slug": "mobile",
                    "name": "Mobile Pass Accepted",
                    "visible": true
                },
                "paved": {
                    "slug": "paved",
                    "name": "Paved",
                    "visible": false
                }
            }
        }`,
    healthCheck: `curl -I "https://api.spothero.com/health/"`,
    checkInventoryRequest: `
    curl -X GET \\
        -H 'Authorization: Bearer $ACCESS_TOKEN' \\
        'https://api.spothero.com/v1/search?latitude=37.774303&longitude=-122.40438410000002&starts=2019-07-01T14:14:01&ends=2019-07-01T18:14:01&max_distance_meters=400'
    `,
    checkInventoryResponse: `
        {
            "facilities": [
                {
                    "id": 4095,
                    "title": "833 Bryant St. - Self Park/Valet Lot",
                    "star_rating": 4.285714285714286,
                    "num_ratings": 7,
                    "latitude": 37.775231,
                    "longitude": -122.403152,
                    "currency": {
                        "type": "usd",
                        "symbol": "$"
                    },
                    "distance_meters": 149.33897580325623,
                    "rates": {
                        "transient": [
                            {
                                "price": 2000,
                                "currency": {
                                    "type": "usd",
                                    "symbol": "$"
                                },
                                "rule_group_id": 3913,
                                "time_offered": {
                                    "start": "2019-07-01T14:14:01-07:00",
                                    "end": "2019-07-02T02:14:01-07:00"
                                },
                                "amenities": {
                                    "airport": false,
                                    "covered_parking": false,
                                    "ev_charging": false,
                                    "handicap_accessible": true,
                                    "heated": false,
                                    "inout_allowed": false,
                                    "on_site_staff": true,
                                    "one_tap": false,
                                    "paved": true,
                                    "rooftop_exposed": false,
                                    "shuttle": false,
                                    "tailgating_permitted": false,
                                    "under_el": false,
                                    "parking_pass_type": "license_plate",
                                    "parking_type": "self-valet-assist"
                                }
                            }
                        ],
                        "event": null
                    }
                },
                {
                    "id": 2287,
                    "title": "801 Bryant St. - Lot",
                    "star_rating": 5,
                    "num_ratings": 1,
                    "latitude": 37.7758020948,
                    "longitude": -122.402688175,
                    "currency": {
                        "type": "usd",
                        "symbol": "$"
                    },
                    "distance_meters": 223.38638766345497,
                    "rates": {
                        "transient": [
                            {
                                "price": 1800,
                                "currency": {
                                    "type": "usd",
                                    "symbol": "$"
                                },
                                "rule_group_id": 2200,
                                "time_offered": {
                                    "start": "2019-07-01T14:14:01-07:00",
                                    "end": "2019-07-02T02:14:01-07:00"
                                },
                                "amenities": {
                                    "airport": false,
                                    "covered_parking": false,
                                    "ev_charging": false,
                                    "handicap_accessible": true,
                                    "heated": false,
                                    "inout_allowed": false,
                                    "on_site_staff": false,
                                    "one_tap": false,
                                    "paved": true,
                                    "rooftop_exposed": false,
                                    "shuttle": false,
                                    "tailgating_permitted": false,
                                    "under_el": false,
                                    "parking_pass_type": "printout",
                                    "parking_type": "self"
                                }
                            }
                        ],
                        "event": null
                    }
                }
            ],
            "max_distance_meters": 400
        }
    `,
};
