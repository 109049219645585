import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Sidebar.module.scss';
import {Icon, Link, VersionToggle} from 'components';
import routes from 'routes';
import {mmddyyyy} from 'utils/format';
import {SidebarContext} from 'contexts';

const SidebarConsumer = Component => props => (
    <SidebarContext.Consumer>
        {context => <Component {...props} {...context} />}
    </SidebarContext.Consumer>
);

export const Sidebar = ({children, className, isOpen, onToggle}) => (
    <div
        {...(isOpen && {
            className: styles.container,
            onClick: onToggle,
        })}
    >
        <aside
            className={classNames(
                styles.Sidebar,
                className,
                isOpen ? styles.open : styles.closed
            )}
            onClick={e => e.stopPropagation()}
        >
            {children}
            <footer className={styles.footer}>
                <p className={styles.lastUpdated}>
                    Last updated: {mmddyyyy(new Date(process.env.BUILD_DATE))}
                </p>
                <div className={styles.icons}>
                    <Link
                        className={styles.button}
                        isButton
                        to={routes.emailSupport}
                    >
                        <Icon className={styles.icon} name="email" />
                    </Link>
                    <Link className={styles.button} isButton to={routes.github}>
                        <Icon className={styles.icon} name="github" />
                    </Link>
                </div>
            </footer>
            <VersionToggle />
        </aside>
    </div>
);

Sidebar.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default SidebarConsumer(Sidebar);
