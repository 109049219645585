import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import classNames from 'classnames';
import styles from './Anchor.module.scss';
import {CopyTarget, Icon, Toast} from 'components';
import {useCopyText, useToast} from 'hooks';
import {withVersion} from 'contexts/version';

const Anchor = ({className, name, hash, version: {slug}}) => {
    const value = hash || camelCase(name);
    const copyValue = [
        window.location.origin,
        window.location.pathname.includes(slug)
            ? window.location.pathname
            : `/${slug}${window.location.pathname}`,
        `#${value}`,
    ].join('');
    const [displayToast, setDisplayToast] = useToast();
    const toggleToast = useCallback(() => setDisplayToast(true), [
        setDisplayToast,
    ]);
    const {copyTargetRef, copyFocusRef, copyText} = useCopyText(toggleToast);

    return (
        <div className={styles.Anchor} data-anchor={value}>
            <a className={styles.landingPoint} name={value} />
            <div className={className}>
                <span
                    className={classNames(styles.link)}
                    onClick={copyText}
                    ref={copyFocusRef}
                >
                    <span className={styles.title}>{name}</span>
                    <span className={styles.toast}>
                        <Icon
                            className={styles.icon}
                            name="anchor"
                            width={20}
                        />
                        <Toast displayToast={displayToast} />
                        <CopyTarget ref={copyTargetRef} value={copyValue} />
                    </span>
                </span>
            </div>
        </div>
    );
};

Anchor.defaultProps = {
    version: {
        slug: '',
    },
};

Anchor.propTypes = {
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Display name of the heading; also generates hash if "hash" is undefined. Example: "Token Generation" jumps to "#tokenGeneration" */
    name: PropTypes.string.isRequired,
    /** Optional custom value for hash. Example: "tokenGeneration_overview" jumps to "#tokenGeneration_overview" */
    hash: PropTypes.string,
    version: PropTypes.shape({
        slug: PropTypes.string,
    }),
};

export default withVersion(Anchor);
