import React from 'react';
import PropTypes from 'prop-types';
import styles from './AdditionalSupport.module.scss';
import classNames from 'classnames';
import {Icon, Link} from 'components';
import routes from 'routes';

const AdditionalSupport = ({children, className}) => {
    return (
        <div className={classNames(styles.AdditionalSupport, className)}>
            <p className="headerLargeAlternate">Additional Support</p>
            <p className="copy">
                Need more help? Don&apos;t be shy &mdash; reach out to our
                development team with your questions.
            </p>
            <ul className={styles.list}>
                <li className={styles.item}>
                    <Link to={routes.github}>
                        <Icon className={styles.icon} name="github" size={24} />
                        SpotHero Github
                    </Link>
                </li>
                <li>
                    <Link to={routes.emailSupport}>
                        <Icon className={styles.icon} name="email" size={24} />
                        Email Us
                    </Link>
                </li>
            </ul>
        </div>
    );
};

AdditionalSupport.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default AdditionalSupport;
