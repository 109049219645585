import React from 'react';
import PropTypes from 'prop-types';
import styles from './Row.module.scss';
import classNames from 'classnames';

export const Row = ({children, className}) => {
    return <div className={classNames(styles.Row, className)}>{children}</div>;
};

Row.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default Row;
