import docs from 'swagger';

const operationId = 'searchFacilityParking';
const version = 'v2.0';

const getByLocation = Object.keys(docs[version].search.paths).find(
    endpoint =>
        docs[version].search.paths[endpoint].get.operationId === operationId
);

export default {
    ParameterTable: {
        data: docs[version].search.paths[getByLocation].get.parameters.map(
            item => ({
                dataType: item.type,
                description: item.description,
                example: item.example,
                name: item.name,
                type: item.in,
                required: item.required,
            })
        ),
    },
    CopyEndpoint: {
        path: getByLocation,
    },
};
