import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import classNames from 'classnames';

const Card = ({children, content, className, onClick}) => {
    const isClickable = typeof onClick === 'function';

    function handleClick(event) {
        if (isClickable) {
            onClick(event);
        }
    }

    return content || children ? (
        <div
            className={classNames(styles.Card, className, {
                [styles.clickTarget]: isClickable,
            })}
            onClick={handleClick}
        >
            {content || children}
        </div>
    ) : null;
};

Card.propTypes = {
    /** Alternative way to pass in content */
    children: PropTypes.any,
    /** MDX-compatible way to pass in content */
    content: PropTypes.any,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Makes the whole card interactive */
    onClick: PropTypes.func,
};

export default Card;
