import React from 'react';
import PropTypes from 'prop-types';
import styles from './AppFooter.module.scss';
import classNames from 'classnames';
import routes from 'routes';
import {Icon, Link} from 'components';

const currentYear = new Date().getFullYear();

const AppFooter = ({className}) => {
    return (
        <footer className={classNames(styles.Footer, className)}>
            <div className={styles.logo}>
                <Link to={routes.spothero}>
                    <Icon horizontalAlign="left" name="logotype" width={77} />
                </Link>
            </div>
            <div className={styles.container}>
                <div className={styles.links}>
                    <ul className={styles.linkList}>
                        <li className={styles.link}>
                            <Link to={routes.emailSupport}>Email Support</Link>
                        </li>
                        <li className={styles.link}>
                            <Link to={routes.license} versionLess>
                                License Agreement
                            </Link>
                        </li>
                    </ul>
                    <p className={styles.copyright}>
                        SpotHero. &copy; {currentYear}. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

AppFooter.propTypes = {
    className: PropTypes.string,
};

export default AppFooter;
