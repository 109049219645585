import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Icon, Link, Toast} from 'components';
import React, {useState, useCallback} from 'react';

import styles from './VersionToggle.module.scss';

import {useToast} from 'hooks';
import {withVersion} from 'contexts/version';

const VersionDisplay = ({display, updated}) => (
    <>
        {display} ({updated})
    </>
);

VersionDisplay.propTypes = {
    display: PropTypes.string,
    updated: PropTypes.string,
};

const VersionToggle = ({version, versions, setVersion}) => {
    const [isOpen, setIsOpen] = useState(false);
    const onToggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);
    const [displayToast, setToast] = useToast();

    return (
        <div className={classNames(styles.wrapper, {[styles.open]: isOpen})}>
            <Toast
                className={styles.toast}
                displayToast={displayToast}
                message="Updated"
                position="top"
            />

            <button className={styles.button} onClick={onToggle}>
                <p>
                    API Version:
                    <br />
                    <VersionDisplay {...version} />
                </p>

                <Icon className={styles.icon} name="arrow" size={20} />
            </button>

            {isOpen && (
                <ul>
                    {versions.map(v => (
                        <li key={v.slug}>
                            <Link
                                className={classNames(styles.version, {
                                    [styles.selected]: v.slug === version.slug,
                                })}
                                to={`/${v.slug}`}
                                onClick={() => {
                                    setVersion({
                                        ...v,
                                        isNewlySelected: true,
                                    });
                                    onToggle();
                                    setToast(true);
                                }}
                            >
                                <VersionDisplay {...v} />

                                {v.slug === version.slug && (
                                    <Icon
                                        className={styles.icon}
                                        name="checkmark"
                                        size={18}
                                    />
                                )}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

VersionToggle.propTypes = {
    version: PropTypes.object,
    setVersion: PropTypes.func,
    versions: PropTypes.arrayOf(PropTypes.object),
};

export default withVersion(VersionToggle);
