import moment from 'moment';
import {SPOTHERO_URL} from 'lang';
import omit from 'lodash/omit';
import pageTypes from 'utils/data/pageTypes';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
const DEFAULT_PARAMETERS = {
    facility_id: '2759', // Citadel garage in Chicago
    facility_slug: '131-s-dearborn-st', // Citadel garage in Chicago
    city_slug: 'chicago-parking',
    venue_slug: 'united-center-parking',
    destination_slug: 'millennium-park-parking',
    city: 'chicago',
};

/**
 * @param {Moment} mom - A moment date
 * @returns {String} - Date formatted as 'YYYY-MM-DDTHH:mm'
 */
function dateTime(mom) {
    return mom
        .set('minute', mom.get('minute') < 30 ? 0 : 30)
        .format(DATE_FORMAT);
}

/**
 * @param {Object} queryParameters - Collection of query paramters in format { [queryParameterName]: queryParameterValue }
 * @returns {String} - Formatted query string chaining valid query parameters
 */
function queryString({
    ends,
    latitude,
    longitude,
    search_string,
    sha_affiliate,
    sid,
    starts,
} = {}) {
    const params = Object.entries({
        ...(Boolean(latitude && longitude) && {
            latitude,
            longitude,
        }),
        ...(Boolean(starts && ends) && {
            starts: dateTime(moment(starts)),
            ends: dateTime(moment(ends)),
        }),
        ...(Boolean(search_string) && {
            search_string,
        }),
        ...(Boolean(sid) && {sid}),
        ...(Boolean(sha_affiliate) && {sha_affiliate}),
    });

    return params.length
        ? `?${params
              .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
              .join('&')}`
        : '';
}

/**
 * @param {String} id - the ID of the page type, e.g. "search"
 * @param {Object} query - a set of query parameters and example values
 * @example getBySchemaId('search') => 'https://spothero.com/search'
 * @returns {String} A full, valid hyperlink to the given page type
 */
function getBySchemaID(id, query = {}) {
    const {path, parameters, unsupportedQueryParameters} = pageTypes.byID[id];

    return `${SPOTHERO_URL}${[
        path,
        parameters.map(param => DEFAULT_PARAMETERS[param]).join('/'),
    ].join('/')}${queryString(omit(query, unsupportedQueryParameters))}`;
}

export default {
    dateTime,
    queryString,
    getBySchemaID,
    DATE_FORMAT,
};
