import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toast.module.scss';
import classNames from 'classnames';
import {Icon} from 'components';

const Toast = ({className, displayToast, message, position}) => {
    return (
        <div
            className={classNames(
                styles.Toast,
                className,
                styles[`Toast--${displayToast ? 'show' : 'hide'}`],
                styles[position]
            )}
        >
            <Icon className={styles.icon} name="checkmark" size={20} />{' '}
            {message || 'Copied!'}
        </div>
    );
};

Toast.defaultProps = {
    position: 'bottom',
};

Toast.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
    displayToast: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'bottom']),
};

export default Toast;
