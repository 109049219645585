import React, {useCallback, createRef} from 'react';
import {AppFooter, Header, Route, Router, Switch} from 'components';
import {AnchorSidebar, LicenseAgreement} from 'pages';
import menu from 'utils/data/menu';
import styles from './App.module.scss';

import routes from 'routes';
import {SidebarContextProvider} from 'contexts/sidebar';
import {
    default as VersionContext,
    VersionContextProvider,
} from 'contexts/version';

import 'sass/main.scss';

const App = props => {
    const scrollContainer = createRef();
    const ScrollToTop = useCallback(() => {
        if (scrollContainer && scrollContainer.current) {
            scrollContainer.current.scrollTop = 0;
        }

        return null;
    }, [scrollContainer]);

    return (
        <Router>
            <Route path={routes.version}>
                <VersionContextProvider>
                    <SidebarContextProvider>
                        <div className={styles.App}>
                            <header className={styles.header}>
                                <VersionContext.Consumer>
                                    {context => (
                                        <Header
                                            menus={menu[context.version.slug]}
                                        />
                                    )}
                                </VersionContext.Consumer>
                            </header>
                            <div className={styles.body} ref={scrollContainer}>
                                <Switch>
                                    <Route
                                        component={LicenseAgreement}
                                        path={routes.license}
                                        exact
                                    />

                                    <Route
                                        component={routeProps => (
                                            <VersionContext.Consumer>
                                                {context => (
                                                    <AnchorSidebar
                                                        {...routeProps}
                                                        menu={
                                                            menu[
                                                                context.version
                                                                    .slug
                                                            ]
                                                        }
                                                    />
                                                )}
                                            </VersionContext.Consumer>
                                        )}
                                        path="/"
                                    />
                                    <Route
                                        component={AnchorSidebar}
                                        path={routes.version}
                                    />
                                </Switch>
                                <Route component={ScrollToTop} />
                                <Route
                                    path={routes.license}
                                    component={AppFooter}
                                />
                            </div>
                        </div>
                    </SidebarContextProvider>
                </VersionContextProvider>
            </Route>
        </Router>
    );
};

export default App;
