import React, {useState} from 'react';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Snippet} from 'components';

const formatTypes = ['example', 'type', 'description'];
const TAB_INDENT = 2;
const indent = (depth = 0) => line =>
    `${' '.repeat(TAB_INDENT * depth)}${line}`;
const getDescription = ({description}) =>
    description && `"${description.replace(/[\r\n\t]/g, ' ')}"`;
/* eslint-disable no-confusing-arrow */
const getNumber = {
    example: ({example}) => example && example.toString(),
    description: getDescription,
    type: ({type}) => `"${type || 'number'}"`,
};
const getEnumerable = {
    example: ({enumerable}) =>
        enumerable.length && `"${enumerable[enumerable.length - 1]}"`,
    description: getDescription,
    type: ({enumerable}) =>
        `"string" /*${enumerable.map(item => `"${item}"`).join(', ')}*/`,
};
const getString = {
    example: ({example}) => example && `"${example}"`,
    description: getDescription,
    type: ({type}) => `"${type || 'string'}"`,
};
/* eslint-enable no-confusing-arrow */

const Schema = ({className, definitions, startingRef, format}) => {
    const [selectedFormat, setSelectedFormat] = useState(format);

    if (!startingRef || !definitions) {
        return null;
    }

    function parseRef(ref) {
        const paths = ref.split('/');

        return get(definitions, paths[paths.length - 1], {});
    }

    function handle(
        {type, properties, items, $ref, enum: enumerable, ...schema},
        depth = 0
    ) {
        switch (enumerable ? 'enumerable' : type) {
            case 'enumerable':
                return getEnumerable[selectedFormat]({
                    enumerable,
                    type,
                    ...schema,
                });
            case 'boolean': // fallthrough
            case 'integer': // fallthrough
            case 'number':
                return getNumber[selectedFormat]({type, ...schema});
            case 'string':
                return getString[selectedFormat]({type, ...schema});
            case 'array':
                return `[${handle(items, depth) || ''}]`;
            case 'object':
                return properties
                    ? [
                          '{',
                          Object.keys(properties)
                              .sort()
                              .map(name => {
                                  const value = handle(
                                      properties[name],
                                      depth + 1
                                  );

                                  return `${name}${
                                      !isUndefined(value) ? `: ${value}` : ''
                                  },`;
                              })
                              .map(indent(depth + 1))
                              .join('\n'),
                          `${indent(depth)('}')}`,
                      ].join('\n')
                    : '{}';
            default:
                if ($ref) {
                    return handle(parseRef($ref), depth);
                }

                return '';
        }
    }

    return (
        <Snippet
            className={className}
            onSelectTab={setSelectedFormat}
            selectedTab={selectedFormat}
            tabs={formatTypes}
        >
            {handle(parseRef(startingRef))}
        </Snippet>
    );
};

Schema.defaultProps = {
    format: formatTypes[0],
};

Schema.propTypes = {
    className: PropTypes.string,
    definitions: PropTypes.object,
    startingRef: PropTypes.string,
    format: PropTypes.oneOf(formatTypes),
};

export default Schema;
