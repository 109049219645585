export default {
    list: [
        'search',
        'city',
        'destination',
        'venue',
        'facilityDetails',
        'checkout',
    ],
    byID: {
        search: {
            id: 'search',
            name: 'Location Search',
            description: 'A list of available facilities alongside a map view',
            path: '/search',
            parameters: [],
            unsupportedQueryParameters: ['sid'],
        },
        facilityDetails: {
            id: 'facilityDetails',
            name: 'Facility Details',
            description:
                'Detailed information about the given parking facility',
            path: '/spot-details',
            parameters: ['facility_id'],
            unsupportedQueryParameters: [
                'sid',
                'latitude',
                'longitude',
                'search_string',
            ],
        },
        checkout: {
            id: 'checkout',
            name: 'Checkout',
            description:
                'A summary of the requested reservation along with forms to complete the transaction',
            path: '/checkout',
            parameters: ['facility_id', 'facility_slug'],
            unsupportedQueryParameters: [
                'sid',
                'latitude',
                'longitude',
                'search_string',
            ],
        },
        city: {
            id: 'city',
            name: 'City Search',
            description:
                'A list of available facilities alongside a map view and additional information about the city',
            path: '',
            parameters: ['city_slug'],
            unsupportedQueryParameters: [
                'sid',
                'latitude',
                'longitude',
                'search_string',
            ],
        },
        destination: {
            id: 'destination',
            name: 'Destination Search',
            description:
                'A list of available facilities alongside a map view and additional information about the point of interest',
            path: '',
            parameters: ['city', 'venue_slug'],
            unsupportedQueryParameters: [
                'sid',
                'latitude',
                'longitude',
                'search_string',
            ],
        },
        venue: {
            id: 'venue',
            name: 'Venue/Event Search',
            description:
                'A list of available facilities alongside a map view and a list of upcoming events at that venue',
            path: '',
            parameters: ['city', 'destination_slug'],
            unsupportedQueryParameters: [
                'sid',
                'latitude',
                'longitude',
                'search_string',
            ],
        },
    },
};
