import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import {withRouter} from 'react-router';
import {Anchor, Link, Callout} from 'components';
import {SPOTHERO, PLATFORM_TITLE} from 'lang';
import routes from 'routes';
import Integration from './Integration';
import Scenario from './Scenario';
import Guides from '../Guides';
import Scenarios from '../Scenarios';
import {withVersion} from 'contexts/version';
import styles from './Overview.module.scss';

const integrations = version =>
    [
        {
            title: 'Parking API',
            content:
                'Completely customize the parking experience for your users with our powerful API.',
            buttonTitle: 'View API Docs',
            url: routes.reference.overview,
        },
        {
            title: 'Mobile Parking SDK',
            content:
                'Our SDK helps your users find, book, and access parking instantly.',
            buttonTitle: 'View SDK Docs',
            url: routes.sdk.overview,
        },
        ...Object.keys(get(Guides, ['meta', version], [])).map(name => ({
            ...Guides.meta[version][name],
            url: [routes.guides, kebabCase(name)].join('/'),
        })),
    ].filter(Boolean);

const scenarios = version =>
    Object.keys(get(Scenarios, ['meta', version], []))
        .map(name => ({
            ...Scenarios.meta[version][name],
            url: [routes.scenarios, kebabCase(name)].join('/'),
        }))
        .filter(scenario => scenario.onOverview)
        .sort((a, b) => a.sort - b.sort);

const Overview = ({history, version}) => (
    <section className={styles.Overview}>
        {version.isNewlySelected && (
            <Callout
                label="Update:"
                type="info"
                withIcon
                className={styles.callout}
            >
                Now viewing version {version.display} documentation.
            </Callout>
        )}
        <Anchor className="headerExtraLarge" name="Overview" />
        <p className="copy">Welcome to the {PLATFORM_TITLE}!</p>
        <p className="copy">
            {`Your use of ${SPOTHERO}'s APIs, SDKs, other developer services, and associated software is subject to the `}
            <Link to={routes.license} target="_blank" versionLess>
                API License Agreement
            </Link>
            .
        </p>
        <div className={styles.cards}>
            {integrations(version.slug).map(integration => (
                <Integration
                    className={styles.card}
                    key={integration.url}
                    {...integration}
                />
            ))}
        </div>
        {Boolean(scenarios(version.slug).length) && (
            <>
                <Anchor
                    className={classNames('headerLarge', styles.section)}
                    name="Common Scenarios"
                />
                <p className="copy">
                    You can use the {PLATFORM_TITLE} to fuel many of the most
                    common user needs with navigating to the last mile. Here are
                    some basic examples of popular scenarios.
                </p>
                <div className={styles.cards}>
                    {scenarios(version.slug).map(scenario => (
                        <Scenario
                            className={styles.card}
                            key={scenario.url}
                            onClick={() => history.push(scenario.url)}
                            {...scenario}
                        />
                    ))}
                </div>
            </>
        )}
    </section>
);

Overview.propTypes = {
    history: PropTypes.object,
    version: PropTypes.object,
};

export default withVersion(withRouter(Overview));
