import {LandingPages, QueryParameters} from './Table';
import {Row, Column, LineBreak} from './Layout';
import AdditionalSupport from './AdditionalSupport';
import Anchor from './Anchor';
import Callout from './Callout';
import Card from './Card';
import CopyEndpoint from './CopyEndpoint';
import Icon from './Icon';
import Integration from 'pages/Overview/Integration';
import Link from './Link';
import Markdown from 'pages/Markdown';
import ParameterTable from './ParameterTable';
import SampleRequest from './SampleRequest';
import Scenario from 'pages/Overview/Scenario';
import Snippet from './Snippet';

export default {
    AdditionalSupport,
    Anchor,
    Callout,
    Card,
    Column,
    CopyEndpoint,
    Icon,
    Integration,
    LandingPages,
    Link,
    LineBreak,
    Markdown,
    ParameterTable,
    QueryParameters,
    Row,
    SampleRequest,
    Scenario,
    Snippet,
};
