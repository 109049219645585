import React from 'react';
import PropTypes from 'prop-types';
import styles from './NotFound.module.scss';
import classNames from 'classnames';
import {Card} from 'components';

const backgroundImages = ['lot', 'flat'];

const NotFound = ({className}) => {
    return (
        <div
            className={classNames(
                styles.NotFound,
                className,
                styles[backgroundImages[Date.now() % 2]]
            )}
        >
            <Card className={styles.card}>
                <p className={classNames('headerExtraLarge', styles.title)}>
                    Whoops, doesn&apos;t look like this page exists.
                </p>
                <p>
                    Check your URL or use the navigation to view a different
                    page.
                </p>
            </Card>
        </div>
    );
};

NotFound.propTypes = {
    className: PropTypes.string,
};

export default NotFound;
