import {useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';

const HEADER_HEIGHT = 80;

/* eslint-disable prefer-arrow-callback */
export default function useScrollContainer(history) {
    const [element, setElement] = useState(null);
    const [children, setChildren] = useState(null);
    const [position, setPosition] = useState(HEADER_HEIGHT);
    const [currentChild, setCurrentChild] = useState({});

    const ref = useCallback(
        node => {
            if (node !== null && !element) {
                setElement(node);
            }
        },
        [element]
    );

    const positionHandler = useCallback(
        evt => {
            setPosition(evt.target.scrollTop + HEADER_HEIGHT + 1);
        },
        [setPosition]
    );

    useEffect(
        function applyChild() {
            if (children) {
                setCurrentChild(
                    children.find(
                        (child, index, arr) =>
                            child.top <= position &&
                            position < get(arr[index + 1], 'top', position + 1)
                    )
                );
            }
        },
        [children, position, setCurrentChild]
    );

    useEffect(
        function updateLocation() {
            if (history && children && currentChild) {
                history.replace({
                    ...history.location,
                    hash: currentChild.hash,
                });
            }
        },
        [children, history, currentChild]
    );

    useEffect(
        function addScrollListener() {
            if (element) {
                const initialPosition = element.scrollTop;
                const anchors = [
                    ...element.querySelectorAll('[data-anchor]'),
                ].map(child => ({
                    top: child.getBoundingClientRect().top + initialPosition,
                    hash: get(child, 'dataset.anchor'),
                }));

                if (anchors.length) {
                    element.addEventListener('scroll', positionHandler);
                    setChildren(anchors);
                    setPosition(initialPosition + HEADER_HEIGHT);
                }
            }

            return () => {
                if (element) {
                    element.removeEventListener('scroll', positionHandler);
                }
            };
        },
        [element, setChildren, setPosition, positionHandler]
    );

    return {
        ref,
    };
}
