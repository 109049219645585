export const supportEmail = 'partner.support@spothero.com';

import {versions} from 'contexts/version';

export default {
    version: `/:version(${versions.map(v => v.slug).join('|')})?`,
    reference: {
        overview: '/reference',
        authentication: '/reference/authentication',
    },
    deepLinks: '/deep-links',
    emailSupport: `mailto:${supportEmail}`,
    github: 'https://github.com/spothero',
    googlePlacesApi:
        'https://developers.google.com/places/web-service/search?utm_source=google&utm_medium=cpc&utm_campaign=FY18-Q2-global-demandgen-paidsearchonnetworkhouseads-cs-maps_contactsal_saf&utm_content=text-ad-none-none-DEV_c-CRE_315916117883-ADGP_Hybrid+%7C+AW+SEM+%7C+BKWS+~+Google+Maps+Place+Search-KWID_43700039136946261-kwd-582432942155-userloc_9060252&utm_term=KW_%2Bgoogle%20%2Bplace%20%2Bsearch-ST_%2Bgoogle+%2Bplace+%2Bsearch&gclid=CNu65cDQlOMCFVOOxQId4acFEA',
    guides: '/guides',
    home: '/',
    sdk: {
        overview: '/sdk',
        ios: 'https://cocoapods.org/pods/SpotHero_iOS_Partner_SDK',
    },
    license: '/terms',
    overview: '/overview',
    register: '/register',
    uiAssets: '/ui-assets',
    webWidgets: '/web-widgets',
    getStarted: '/get-started',
    reports: '/reports',
    account: '/account',
    support: '/support',
    releaseNotes: '/release-notes',
    requestAffiliateAccount: `mailto:${supportEmail}?subject=Parking Mobility Platform | Register as an Affiliate&body=${[
        'Name',
        'Company',
        'Title',
        'Corporate Email',
        'Anticipated Number of Reservations per Month',
        'SpotHero Account Email',
    ]
        .map(item => `${encodeURIComponent(item)}:`)
        .join('%0D%0A')}`,
    requestAPIKey: `mailto:${supportEmail}?subject=Parking Mobility Platform | Request API Key&body=${[
        'Name',
        'Company',
        'Title',
        'Corporate Email',
        'Anticipated Number of Reservations per Month',
        'SpotHero Account Email',
    ]
        .map(item => `${encodeURIComponent(item)}:`)
        .join('%0D%0A')}`,
    requestSDKAccess: `mailto:${supportEmail}?subject=Parking Mobility Platform | Request iOS SDK&body=${[
        'Name',
        'Company',
        'Title',
        'Corporate Email',
        'Anticipated Number of Reservations per Month',
    ]
        .map(item => `${encodeURIComponent(item)}:`)
        .join('%0D%0A')}`,
    scenarios: '/scenarios',
    spothero: 'https://spothero.com',
};
