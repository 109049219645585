import {useCallback, useRef} from 'react';

export default function useCopyText(onCopy) {
    const copyTargetRef = useRef(null);
    const copyFocusRef = useRef(null);
    const copyText = useCallback(
        event => {
            if (document && copyTargetRef) {
                copyTargetRef.current.focus();
                copyTargetRef.current.select();
                document.execCommand('copy');

                if (onCopy) {
                    onCopy();
                }

                if (copyFocusRef) {
                    copyFocusRef.current.focus();
                }
            }
        },
        [onCopy, copyTargetRef, copyFocusRef]
    );

    return {
        copyTargetRef,
        copyFocusRef,
        copyText,
    };
}
