import React, {forwardRef, useContext} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import startsWith from 'lodash/startsWith';
import {Link as ReactRouterLink} from 'react-router-dom';
import styles from './Link.module.scss';
import {VersionContext} from 'contexts';

export const Link = forwardRef(
    (
        {
            children,
            isButton,
            content,
            className,
            forceAnchor,
            href,
            name,
            to,
            versionLess,
            ...props
        },
        ref
    ) => {
        const {version, versions} = useContext(VersionContext);
        const body = content || children;
        // normalize two potential options
        // to appears to never be an object
        const path = to || href;
        const isExternal = startsWith(path, 'http');
        const isMailto = startsWith(path, 'mailto');
        const hasVersion = versions.filter(v => path.includes(v.slug)).length;
        const versionedPath =
            isExternal || isMailto || hasVersion || versionLess
                ? path
                : `/${version.slug}${path}`;
        const classNames = cn(
            styles.Link,
            isButton && styles.button,
            className
        );

        if (!path) {
            return null;
        } else if (forceAnchor || name || isExternal || isMailto) {
            return (
                <a
                    className={classNames}
                    href={versionedPath}
                    name={name}
                    ref={ref}
                    {...(isExternal && {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    })}
                    {...props}
                >
                    {body}
                </a>
            );
        } else {
            return (
                <ReactRouterLink
                    className={classNames}
                    to={versionedPath}
                    {...props}
                >
                    {body}
                </ReactRouterLink>
            );
        }
    }
);

Link.propTypes = {
    /** Apply button styles */
    isButton: PropTypes.bool,
    /** Alternative way to pass content */
    children: PropTypes.node,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** MDX-compatible way to pass content */
    content: PropTypes.string,
    /** Use a DOM <a> tag instead of React Router */
    forceAnchor: PropTypes.bool,
    /** URL or path destination for click */
    href: PropTypes.string,
    /** Enables anchor reference with hash in URL */
    name: PropTypes.string,
    /** Enables alternative click function to href */
    onClick: PropTypes.func,
    /** URL or path destination for click */
    to: PropTypes.string,
    versionLess: PropTypes.bool,
};

export default Link;
