import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './QueryParameters.module.scss';
import classNames from 'classnames';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import {Callout, Form, Table, Select} from 'components';
import pageTypes from 'utils/data/pageTypes';
import moment from 'moment';
import {referralURL} from 'utils/format';
import {useHistory} from 'react-router-dom';

const replacePattern = {
    sha_affiliate: /[^A-Z0-9\-_]/gi,
    facility_id: /[^0-9]/gi,
    location: /[^0-9.]/gi,
};
const configurationPattern = {
    datetime: '20\\d{2}-\\d{2}-\\d{2}T\\d{2}:[3|0]0\\b',
};

export const DEFAULT_STATE = {
    sha_affiliate: `GOOD_AFFILIATE_${new Date().getFullYear()}`,
    latitude: '41.8799308', // 125 S. Clark St., Chicago, IL
    longitude: '-87.6305171', // 125 S. Clark St., Chicago, IL
    starts: referralURL.dateTime(moment()),
    ends: referralURL.dateTime(moment().add(3, 'hours')),
    sid: '2759', // Citadel garage in Chicago
    search_string: 'Chicago',
};

export const QUERY_PARAMETERS = {
    list: [
        'sha_affiliate',
        'starts',
        'ends',
        'latitude',
        'longitude',
        'search_string',
    ],
    byID: {
        latitude: {
            id: 'latitude',
            name: 'Latitude',
            configuration: {
                pattern: configurationPattern.location,
            },
            description: (
                <p>
                    Centers the search at the given latitude. Requires{' '}
                    <code>longitude</code>.
                </p>
            ),
            defaultValue: 'The browser geolocation',
            formatValue: value => {
                return value.replace(replacePattern.location, '');
            },
        },
        longitude: {
            id: 'longitude',
            name: 'Longitude',
            configuration: {
                pattern: configurationPattern.location,
            },
            description: (
                <p>
                    Centers the search at the given longitude. Requires{' '}
                    <code>latitude</code>.
                </p>
            ),
            defaultValue: 'The browser geolocation',
            formatValue: value => {
                return value.replace(replacePattern.location, '');
            },
        },
        starts: {
            id: 'starts',
            name: 'Start Time',
            configuration: {
                maxLength: 16,
                min: DEFAULT_STATE.starts,
                max: referralURL.dateTime(
                    moment(DEFAULT_STATE.starts).add(2, 'years')
                ),
                minLength: 16,
                pattern: configurationPattern.datetime,
                step: 1800,
                type: 'datetime-local',
            },
            description: <p>Sets the start time of the reservation.</p>,
            defaultValue: 'The current time rounded to the previous half hour.',
            formatValue: value => value,
            hint:
                'Must be rounded to the previous half-hour, within 2 years, and before the end time.',
        },
        ends: {
            id: 'ends',
            name: 'End Time',
            configuration: state => ({
                maxLength: 16,
                min: referralURL.dateTime(moment(state.starts)),
                max: referralURL.dateTime(
                    moment(DEFAULT_STATE.starts).add(2, 'years')
                ),
                minLength: 16,
                pattern: configurationPattern.datetime,
                step: 1800,
                type: 'datetime-local',
            }),
            description: (
                <p>
                    Sets the end time of the reservation. Requires{' '}
                    <code>starts</code>.
                </p>
            ),
            defaultValue: 'The given start time plus 3 hours.',
            formatValue: value => value,
            hint:
                'Must be rounded to the next half-hour, within 2 years, and after the start time.',
        },
        sha_affiliate: {
            id: 'sha_affiliate',
            name: 'Affiliate Code',
            configuration: {
                pattern: configurationPattern.sha_affiliate,
                maxLength: 25,
            },
            description: 'Associates referral transactions with your account.',
            defaultValue: '',
            formatValue: value =>
                value.replace(replacePattern.sha_affiliate, ''),
        },
        search_string: {
            id: 'search_string',
            name: 'Search String',
            description:
                'Alternative to latitude and longitude. If latitude and longitude are provided, they will take precedence. Otherwise, centers search around the latitude and longitude of the given search string.',
            defaultValue: '',
            configuration: {
                pattern: configurationPattern.search_string,
            },
            formatValue: value => value,
        },
        sid: {
            id: 'sid',
            name: 'Facility ID',
            description:
                'Selects the given facility within the list of results.',
            configuration: {
                min: 0,
                pattern: configurationPattern.facility_id,
            },
            formatValue: value => value.replace(replacePattern.facility_id, ''),
        },
    },
};

const QueryParameters = ({className}) => {
    const [state, setState] = useState(DEFAULT_STATE);
    const [errorState, setErrorState] = useState({});
    const formRef = useRef(null);
    const history = useHistory();
    const schemaID = get(history, 'location.state.schemaID', 'search');

    useEffect(() => {
        const elements = get(formRef, ['current', '_form', 'elements']);

        if (elements) {
            setErrorState(
                [...elements]
                    .filter(element => element.validationMessage)
                    .reduce(
                        (fields, field) => ({
                            ...fields,
                            [field.name]: field.validationMessage,
                        }),
                        {}
                    )
            );
        }
    }, [formRef, state]);

    function handleInput(evt) {
        evt.preventDefault();
        const {name, value} = evt.target;

        setState({
            ...state,
            [name]: QUERY_PARAMETERS.byID[name].formatValue(value),
        });
    }

    function handleSelect({label, value}) {
        history.replace({
            ...history.location,
            state: {
                schemaID: value,
            },
        });
    }

    const url = referralURL.getBySchemaID(schemaID, state);

    return (
        <Form
            className={classNames(styles.QueryParameters, className)}
            id="QueryParameters"
            ref={formRef}
        >
            <Callout className={styles.callout} type="info">
                <div className={styles.example}>
                    <p className={styles.title}>Example Referral URL</p>
                    <Select
                        className={classNames('Select', styles.select)}
                        defaultValue={schemaID}
                        items={pageTypes.list.map(item => ({
                            label: `${startCase(item)} Page`,
                            value: item,
                        }))}
                        onChange={handleSelect}
                    />
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {url.split('&').map((segment, index) => (
                            <span key={index}>
                                {index !== 0 && '&'}
                                {segment}
                            </span>
                        ))}
                    </a>
                </div>
            </Callout>
            <Table
                className={classNames(styles.table, styles.parameters)}
                headers={['Description', 'Query']}
                data={QUERY_PARAMETERS.list
                    .map(id => QUERY_PARAMETERS.byID[id])
                    .map(
                        ({
                            configuration,
                            defaultValue,
                            description,
                            hint,
                            id,
                            name,
                        }) => [
                            {
                                component: () => (
                                    <div key="name">
                                        <p className={styles.name}>{name}</p>
                                        <div>{description}</div>
                                        {defaultValue && (
                                            <p className={styles.default}>
                                                {defaultValue}
                                            </p>
                                        )}
                                    </div>
                                ),
                                className: styles.item,
                            },
                            {
                                component: () => (
                                    <div className={styles.query} key="id">
                                        <p className={styles.name}>{name}</p>
                                        <code>{id}</code>
                                        <input
                                            className={classNames(
                                                styles.input,
                                                {
                                                    [styles.invalid]:
                                                        errorState[id],
                                                }
                                            )}
                                            disabled={pageTypes.byID[
                                                schemaID
                                            ].unsupportedQueryParameters.includes(
                                                id
                                            )}
                                            id={id}
                                            key="example"
                                            {...(id === 'sha_affiliate' && {
                                                maxLength: 25,
                                            })}
                                            name={id}
                                            onChange={handleInput}
                                            placeholder={
                                                pageTypes.byID[
                                                    schemaID
                                                ].unsupportedQueryParameters.includes(
                                                    id
                                                )
                                                    ? 'Not supported by this page type'
                                                    : DEFAULT_STATE[id]
                                            }
                                            readOnly={[
                                                'latitude',
                                                'longitude',
                                                'search_string',
                                            ]
                                                .concat(
                                                    pageTypes.byID[schemaID]
                                                        .unsupportedQueryParameters
                                                )
                                                .includes(id)}
                                            type="text"
                                            value={
                                                DEFAULT_STATE[id] ===
                                                    state[id] ||
                                                pageTypes.byID[
                                                    schemaID
                                                ].unsupportedQueryParameters.includes(
                                                    id
                                                )
                                                    ? id === 'starts' ||
                                                      Boolean(
                                                          id === 'ends' &&
                                                              state.starts &&
                                                              state.ends
                                                      )
                                                        ? DEFAULT_STATE[id]
                                                        : ''
                                                    : state[id]
                                            }
                                            {...(typeof configuration ===
                                            'function'
                                                ? configuration(state)
                                                : configuration)}
                                        />
                                        {[
                                            'latitude',
                                            'longitude',
                                            'search_string',
                                        ].includes(id) &&
                                            !pageTypes.byID[
                                                schemaID
                                            ].unsupportedQueryParameters.includes(
                                                id
                                            ) && (
                                                <p className={styles.hint}>
                                                    For this example, the
                                                    location is fixed to
                                                    Chicago.
                                                </p>
                                            )}
                                        {Boolean(hint) && (
                                            <p className={styles.hint}>
                                                {hint}
                                            </p>
                                        )}
                                        {Boolean(errorState[id]) && (
                                            <p className={styles.error}>
                                                {errorState[id]}
                                            </p>
                                        )}
                                    </div>
                                ),
                                className: styles.item,
                            },
                        ]
                    )}
            />
        </Form>
    );
};

QueryParameters.propTypes = {
    /** Hook to pass in custom styles */
    className: PropTypes.string,
};

export default QueryParameters;
