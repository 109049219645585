
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import DSM from 'components/dsm';
import data from './Authentication.data.js';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Authentication`}</h1>
    <DSM.Row>
      <DSM.Column>
        <h2>{`Request a Key`}</h2>
        <p>{`Quickly get an API created by our team.`}</p>
        <DSM.LineBreak />
        <DSM.Link content="Request API Key" isButton to="mailto:partner.support@spothero.com?subject=Parking Mobility Platform | Request API Key&body=Name:%0D%0ACompany:%0D%0ATitle:%0D%0ACorporate%20Email:%0D%0AAnticipated%20Number%20of%20Reservations%20per%20Month:%0D%0ASpotHero%20Account%20Email:" />
      </DSM.Column>
      <DSM.Column>
        <h2>{`Need Help?`}</h2>
        <p>{`Lost your key or having trouble?`}</p>
        <DSM.LineBreak />
        <DSM.Link content="Contact Us" to="mailto:partner.support@spothero.com?subject=Parking%20Mobility%20Platform%20Support" />
      </DSM.Column>
    </DSM.Row>
    <h2>{`Authentication`}</h2>
    <p>{`All calls to SpotHero's API must be authenticated using an access token with the correct scope obtained using an appropriate OAuth 2.0 grant flow.`}</p>
    <p>{`Calls made without a valid access token will return a response with a `}<inlineCode parentName="p">{`401 Unauthorized`}</inlineCode>{` HTTP Status Code.`}</p>
    <DSM.Snippet language="curl" content={data["401 unauthorized"]} />
    <h2>{`Token Generation Grant Flows`}</h2>
    <p>{`SpotHero currently only supports the `}<em parentName="p">{`Client Credentials Grant`}</em>{` flow.`}</p>
    <h3>{`Client Credentials Grant`}</h3>
    <p>{`Obtain an access token using the Client Credentials grant by specifying the following query parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`grant_type`}</inlineCode>{` of `}<inlineCode parentName="li">{`client_credentials`}</inlineCode></li>
    </ul>
    <p>{`In addition, the request for an access token must be authenticated by providing an `}<inlineCode parentName="p">{`Authorization Basic`}</inlineCode>{` HTTP Header consisting of the Base64 hashed tuple of `}<inlineCode parentName="p">{`($CLIENT_ID:$CLIENT_SECRET)`}</inlineCode>{`. Your client ID and secret was provided to you when provisioning your access by `}<a parentName="p" {...{
        "href": "mailto:partner.support@spothero.com"
      }}>{`SpotHero Support`}</a>{`. Your client ID and secret should be treated securely. If they are lost, stolen, or used unethically, we reserve the right to disable your client ID and secret without warning.`}</p>
    <p>{`Finally, provide the Content Type header as Form URL encoded:
`}<inlineCode parentName="p">{`Content-Type: application/x-www-form-urlencoded`}</inlineCode>{`.`}</p>
    <h4>{`Example Client Credentials Grant Request & Response`}</h4>
    <DSM.Snippet language="curl" content={data["client credentials grant"]} />
    <h2>{`Authorizing API Calls`}</h2>
    <p>{`Once obtained using the appropriate grant, the access token should be provided as an `}<inlineCode parentName="p">{`Authorization Bearer`}</inlineCode>{` HTTP header.`}</p>
    <h3>{`Example Authorized Request & Response`}</h3>
    <p>{`When properly authorized, the request should return a response with a `}<inlineCode parentName="p">{`2XX`}</inlineCode>{` HTTP Status Code. The individual API endpoint documentation will have information about exactly what successful status codes can be returned.`}</p>
    <DSM.Snippet language="curl" content={data["authorized request and response"]} />
    <h3>{`Unauthorized Requests & Response`}</h3>
    <p>{`Unauthorized API calls typically happen for one of two reasons:`}</p>
    <ul>
      <li parentName="ul">{`the access token used has expired; or`}</li>
      <li parentName="ul">{`the access token has inadequate `}<inlineCode parentName="li">{`scope`}</inlineCode>{` granted`}</li>
    </ul>
    <p>{`In both cases, please re-obtain a valid access token using the appropriate grant flow and scope request. You can avoid the issue of your token expiring by obtaining a new token `}<em parentName="p">{`before`}</em>{` the existing token expires.`}</p>
    <p>{`When the request is improperly authorized, you will receive a `}<inlineCode parentName="p">{`403`}</inlineCode>{` HTTP Status in the response.`}</p>
    <DSM.Snippet language="curl" content={data["403 http status response"]} />
    <DSM.AdditionalSupport />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;