import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import Prism from 'prismjs';
import PropTypes from 'prop-types';
import styles from './Snippet.module.scss';
import snippets from './data';
import ActionBar from './ActionBar';
import {useSwipe} from 'hooks';

// Prism curl (https://prismjs.com/extending.html)
Prism.languages.curl = {
    curl: /\bcurl\b/,
    url: /https?:[a-zA-Z0-9:.?=/\-_{}&@]*/,
    parameter: /[^\S][\w|-]+:(?=\s)/,
    value: [
        {
            pattern: /([=])([A-Za-z0-9-_.]*)/,
            lookbehind: true,
        },
        {
            pattern: /(-u )([A-Za-z0-9-_.{}]*)/,
            lookbehind: true,
        },
    ],
    string: {
        pattern: /(:\s)((["'])(\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\3)/,
        lookbehind: true,
    },
    option: /[\s]-[a-zA-Z]*\b/,
    comment: />|<|\\|\.{3}/g,
};

/**
 * Removes excess whitespace from the front of each line while maintaining relative indentations
 * @static
 * @function trimLeadingWhitespace
 * @param {String} code - Preformatted (template) string that may be multiple lines with varying indentation
 * @returns {String} Formatted string without excess leading whitespace
 */
const trimLeadingWhitespace = (code = '') => {
    const lines = code.split(/\r?\n/);
    const leadingWhitespace = Math.min(
        ...lines.filter(Boolean).map(c => c.search(/\S|$/))
    );

    return lines
        .map(line => line.substr(leadingWhitespace))
        .map(line => (line === '\\n' ? '\n' : line)) // eslint-disable-line no-confusing-arrow
        .filter(Boolean)
        .join('\n'); // eslint-disable-line quotes
};

const Snippet = ({
    content,
    children,
    className,
    language,
    maxHeight,
    name,
    onSelectTab,
    selectedTab,
    tabs,
}) => {
    const body = content || children || trimLeadingWhitespace(snippets[name]);
    const [isModal, setIsModal] = useState(false);
    const {handleEndMove, handleStartMove} = useSwipe(onSwipe);

    const handleToggleModal = useCallback(() => {
        setIsModal(!isModal);
    }, [isModal]);

    function handleTabSelect(evt) {
        return onSelectTab && onSelectTab(evt.target.value);
    }

    function onSwipe(swipe) {
        if (tabs && tabs.length) {
            const tabIndex = tabs.findIndex(tab => tab === selectedTab);

            if (swipe.left && tabIndex < tabs.length) {
                onSelectTab(tabs[tabIndex + 1]);
            } else if (swipe.right && tabIndex > 0) {
                onSelectTab(tabs[tabIndex - 1]);
            }
        }
    }

    return body ? (
        <div className={classNames(styles.Snippet, className)}>
            <div className={styles.container}>
                {!isModal && (
                    <ActionBar
                        codeSample={body}
                        isModal={isModal}
                        name={name}
                        onSelectTab={handleTabSelect}
                        selectedTab={selectedTab}
                        onToggleModal={handleToggleModal}
                        tabs={tabs}
                    />
                )}
                <div
                    className={classNames(
                        styles.scrollContainer,
                        isModal ? styles.modal : styles.embedded,
                        {[styles.withTabs]: Boolean(tabs && tabs.length)}
                    )}
                    style={{
                        maxHeight,
                    }}
                    {...(!isModal && {onClick: handleToggleModal})}
                >
                    {isModal && (
                        <ActionBar
                            codeSample={body}
                            isModal={isModal}
                            name={name}
                            onSelectTab={handleTabSelect}
                            selectedTab={selectedTab}
                            onToggleModal={handleToggleModal}
                            tabs={tabs}
                        />
                    )}
                    <pre
                        className={styles.pre}
                        {...(!isModal && {
                            onTouchStart: handleStartMove,
                            onTouchEnd: handleEndMove,
                        })}
                    >
                        <code
                            className={classNames(
                                `language-${language}`,
                                styles.code
                            )}
                            dangerouslySetInnerHTML={{
                                __html: Prism.highlight(
                                    body,
                                    Prism.languages[language],
                                    language
                                ),
                            }}
                        />
                    </pre>
                </div>
            </div>
        </div>
    ) : null;
};

Snippet.defaultProps = {
    children: null,
    language: 'js',
};

Snippet.propTypes = {
    /** Alternative way to pass in content */
    children: PropTypes.string,
    /** MDX-compatible way to pass in content */
    content: PropTypes.string,
    /** Hook to pass in custom styles */
    className: PropTypes.string,
    /** Set syntax highlighting based on language */
    language: PropTypes.oneOf([
        'curl',
        'clike',
        'css',
        'html',
        'javascript',
        'js',
        'markup',
        'mathml',
        'svg',
        'xml',
    ]),
    /** A number in px to set the maximum height of the content */
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Hook to pass in the name of a stored code snippet from ./data.js */
    name: PropTypes.string,
    /** Callback function to receive the name of the clicked tab */
    onSelectTab: PropTypes.func,
    /** The name of the highlighted tab */
    selectedTab: PropTypes.string,
    /** Set of tab names will appear in the given order */
    tabs: PropTypes.arrayOf(PropTypes.string),
};

export default Snippet;
