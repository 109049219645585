import React from 'react';
import classNames from 'classnames';
import styles from './LicenseAgreement.module.scss';
import {Link} from 'components';

const LicenseAgreement = () => {
    return (
        <div className={styles.LicenseAgreement}>
            <h1 className={styles.title}>
                SpotHero Developer Program and Portal
            </h1>
            <h2 className={styles.subtitle}>
                Terms of Use and API License Agreement
            </h2>
            <p>
                Thank you for using SpotHero&apos;s APIs, other developer
                services, and associated software (collectively{' '}
                <strong>&quot;APIs&quot;</strong>). By accessing or using our
                APIs, or by using or interacting with any Features or Content,
                you and any entity, person, or company that you represent
                (collectively <strong>&quot;You&quot;</strong> or{' '}
                <strong>&quot;Your&quot;</strong>) agree to be bound by these
                terms of use, including all terms and policies referenced herein
                (the <strong>&quot;Terms of Use&quot;</strong>). You agree to
                comply with the Terms and that the Terms control Your
                relationship with us. Please read them carefully each time You
                access or use the APIs.
            </p>
            <p>
                <strong>
                    YOU ARE AGREEING THAT YOU HAVE READ, AND THAT YOU AGREE TO
                    COMPLY WITH AND TO BE BOUND BY THE TERMS AND ALL APPLICABLE
                    LAWS AND REGULATIONS IN THEIR ENTIRETY WITHOUT LIMITATION OR
                    QUALIFICATION. IF YOU DO NOT AGREE TO BE BOUND BY THESE
                    TERMS, THEN YOU MAY NOT ACCESS OR OTHERWISE USE THE APIs.
                </strong>
            </p>
            <p>
                If you use the APIs as an interface to, or in conjunction with
                other SpotHero products or services, then the terms for those
                other products or services also apply.
            </p>

            <ol className={styles.headings}>
                <li>
                    <h3 className={styles.heading}>Definitions.</h3>
                    <ol className={styles.articles}>
                        <li>
                            <strong>SpotHero</strong> shall mean SpotHero, Inc.,
                            on behalf of itself and its affiliates. We may refer
                            to SpotHero as &quot;we&quot;, &quot;our&quot;, or
                            &quot;us&quot; in the Terms.
                        </li>
                        <li>
                            <strong>SpotHero API Platform</strong> shall mean an
                            application programming interface located at
                            https://spothero.com/ or any of its subdomains.
                        </li>
                        <li>
                            <strong>Features</strong> means any APIs, software
                            development kits, SpotHero sites, available SpotHero
                            services, and any other materials, tools, and
                            systems provided or made available to You by the
                            SpotHero API Platform.
                        </li>
                        <li>
                            <strong>Application</strong> shall mean any
                            application, website, interface, or other
                            communication method You develop or use to interact
                            with SpotHero, the APIs or any Feature.
                        </li>
                        <li>
                            <strong>Users</strong> shall mean any of Your end
                            users of the APIs, Features, or your services.
                        </li>
                        <li>
                            <strong>Personal Information</strong> shall mean any
                            information pertaining to a directly or indirectly
                            identifiable individual.
                        </li>
                        <li>
                            <strong>Confidential Information</strong> means any
                            non-public or otherwise restricted information
                            available or provided to You pursuant to your use
                            and/or access of the APIs, Features, or SpotHero API
                            Platform.
                        </li>
                    </ol>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Eligibility and Authorization.
                    </h3>
                    <p>
                        Use of the APIs and Features are not intended by anyone
                        under the age of 18. You may not use the APIs or
                        Features, and may not accept the Terms if (a) you are
                        not of legal age to form a binding contract with
                        SpotHero or (b) you are a person barred from using or
                        receiving the APIs under the applicable laws of the
                        United States or any other countries in which you are a
                        resident or from which you use the APIs. By using,
                        accessing or interacting with the APIs or Features You
                        represent and warrant that You and Your Users are 18
                        years of age or older and have the ability and right to
                        use and access the APIs and Features.
                    </p>
                    <p>
                        If You are using the APIs or Features on behalf of an
                        entity or company, You represent and warrant that you
                        have authority to bind that entity or company to the
                        Terms and that by accepting the Terms; you are doing so
                        on behalf of that entity or company.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Registration.</h3>
                    <p>
                        In order to access the APIs and Features, You are
                        required to register and establish user identification
                        credentials with a password and may be required to
                        provide certain information (such as contact details) as
                        part of the registration process (
                        <strong>&quot;Registration Information&quot;</strong>).
                        You agree to provide SpotHero with accurate and complete
                        Registration Information and inform Us immediately or
                        any updates or other changes to such Information.
                    </p>
                    <p>
                        You shall be solely responsible for maintaining the
                        confidentiality of Your Registration Information and are
                        fully responsible for all activities that occur pursuant
                        to access with Your Registration Information, whether or
                        not You have authorized such activities. You agree to
                        immediately notify SpotHero of any unauthorized use of
                        Your Registration Information. Notice of such
                        unauthorized use will not relieve You of responsibility
                        for any activities, damage, or harm that occurs as a
                        result.
                    </p>
                    <p>
                        You agree to adhere at all times to industry-best
                        security practices. You shall not violate the security
                        or integrity of any network, computer or communications
                        system, software application, or network or computing
                        device, whether or not related to the APIs or Features
                        in general or otherwise.
                    </p>
                    <p>
                        You will not misrepresent or mask either Your identity
                        or the identity of Your Users when using the APIs or
                        Features.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Compliance with Law, Third Party Rights, and Other
                        Terms.
                    </h3>
                    <p>
                        You agree to comply with all applicable laws,
                        regulations, and third party rights (including without
                        limitation laws regarding the import or export of data
                        or software, privacy, and any local laws). You agree not
                        to use the APIs or Features to encourage or promote
                        illegal activity or violation of third party rights and
                        that you will not violate any other terms of service
                        with SpotHero.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Grant of Rights and Restrictions.
                    </h3>
                    <ol
                        className={classNames(styles.articles, styles.longform)}
                    >
                        <li>
                            <strong>
                                Limited License to APIs or Features.
                            </strong>
                        </li>
                        <p>
                            Upon provision of access to the APIs or Features,
                            SpotHero hereby grants you a nonexclusive,
                            nontransferable, non-sublicenseable, fully-paid,
                            revocable license and right to use the APIs to
                            incorporate a Feature into Your Application.
                        </p>
                        <p>
                            Except as expressly provided herein, nothing in
                            these Terms grants You rights in or to the SpotHero
                            APIs, Features, or any patent, trademark, copyright
                            or other intellectual property derived from the APIs
                            or Features.
                        </p>
                        <li>
                            <strong>Limited License to Trademark.</strong>
                        </li>
                        <p>
                            SpotHero grants to You a non-exclusive,
                            non-transferable, non-sublicenseable limited license
                            to display the SpotHero trademark and logo made
                            available through the SpotHero API Platform for such
                            usage (the{' '}
                            <strong>&quot;SpotHero Marks&quot;</strong>) solely
                            in connection with offering Your Users the
                            opportunity to interact with SpotHero as permitted
                            by these Terms. You agree that any such use of the
                            SpotHero Marks shall fully comply with all SpotHero
                            guidelines and requirements. Further, SpotHero
                            reserves the right to review and approve any uses of
                            the SpotHero Marks.
                        </p>
                        <p>
                            You acknowledge and agree that you do not receive
                            any right or interest in and to any SpotHero Marks
                            and that all goodwill generated from Your use of the
                            SpotHero Marks pursuant to this Agreement inures
                            solely to the benefit of SpotHero. Further, You
                            acknowledge and agree that SpotHero may terminate or
                            modify this limited license or the applicable
                            requirements for Your use at any time.
                        </p>
                    </ol>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Personally Identifiable Information.
                    </h3>
                    <p>
                        You acknowledge that Your use of the APIs and Features
                        may allow You to collect Personal Information from third
                        parties. You agree that unless collected from Your
                        Users, You will delete Personal Information when it is
                        no longer necessary for Your performance generally or
                        for your Users or when You cease use of the APIs and
                        Features. You further agree that Your Application and/or
                        Your use of the APIs and Features will comply with all
                        applicable laws, regulations, and best practices,
                        including those concerning privacy, data protection, and
                        user identity.
                    </p>
                    <p>
                        To the extent necessary, You will provide and adhere to
                        a privacy policy for Your Application that clearly and
                        accurately describes to Users of Your Application what
                        Personal Information you collect and how you use and
                        share such information (including for advertising) with
                        SpotHero and third parties.
                    </p>
                    <p>
                        While we do not expect to provide You with personal
                        information of any other person, in the event that we
                        were to do so (as authorized by our Privacy Policy,
                        available at{' '}
                        <Link to="https://spothero.com/privacy-policy">
                            https://spothero.com/privacy-policy
                        </Link>
                        , as updated from time to time, by applicable law and/or
                        by other applicable governing document(s)), You
                        understand and agree that (a) You are a &quot;service
                        provider&quot; as defined by the California Consumer
                        Privacy Act (the <strong>&quot;Act&quot;</strong>), (b)
                        You are not receiving any personal information from
                        SpotHero or its affiliates as consideration for any
                        services or other items provided to You, (c) You are
                        prohibited from retaining, using or disclosing such
                        personal information for any purpose other than for the
                        specific purpose of performing the services relating to
                        Your relationship with SpotHero, including retaining,
                        using or disclosing such personal information for a
                        commercial purpose other than providing such services,
                        (d) You are prohibited from retaining, using or
                        disclosing such personal information outside of the
                        direct business relationship between You and SpotHero,
                        and (e) You will not further collect, sell or use such
                        personal information except as necessary to perform the
                        business purpose. If SpotHero is required to direct You
                        to delete such personal information, You agree to comply
                        with such request in accordance with the Act. You hereby
                        certify that You understand the restrictions set forth
                        herein and that You will comply with them. For purposes
                        of this paragraph, the terms &quot;business
                        purpose,&quot; &quot;personal information,&quot;
                        &quot;service provider,&quot; and &quot;sale&quot; are
                        as defined in the Act.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Restrictions on Rights to Use.
                    </h3>
                    <p>
                        As a condition to Your use of the APIs, SpotHero API
                        Platform, or Features or any other SpotHero content, You
                        agree You shall not engage in the following or permit
                        any third parties to engage in the following:
                    </p>
                    <ul className={styles.bullets}>
                        <li>
                            Sublicense an API for use by a third party.
                            Consequently, you will not create an Application
                            that functions substantially the same as the APIs
                            and offer it for use by third parties.
                        </li>
                        <li>
                            Perform an action with the intent of introducing to
                            SpotHero products and services any viruses, worms,
                            defects, Trojan horses, malware, or any items of a
                            destructive nature.
                        </li>
                        <li>
                            Distribute, publish or allow access or linking to
                            the APIs, Features, or SpotHero API Platform from
                            any location or source other than Your Application.
                        </li>
                        <li>
                            Defame, abuse, harass, stalk, or threaten others.
                        </li>
                        <li>
                            Interfere with or disrupt the APIs or the servers or
                            networks providing the APIs.
                        </li>
                        <li>
                            Promote or facilitate unlawful online gambling or
                            disruptive commercial messages or advertisements.
                        </li>
                        <li>
                            Reverse engineer or attempt to extract the source
                            code from any API or any related software, except to
                            the extent that this restriction is expressly
                            prohibited by applicable law.
                        </li>
                        <li>
                            Use the APIs for any activities where the use or
                            failure of the APIs could lead to death, personal
                            injury, or environmental damage (such as the
                            operation of nuclear facilities, air traffic
                            control, or life support systems).
                        </li>
                        <li>
                            Remove any copyright, trademark, attribution or
                            other proprietary rights notice contained in or on
                            the APIs, Features, or SpotHero API Platform.
                        </li>
                        <li>
                            Remove, obscure, or alter these Terms or any other
                            SpotHero terms and conditions or any links to or
                            notices of those terms.
                        </li>
                        <li>
                            Use any robot, spider, or other device to retrieve
                            or index any portion of the APIs, Features, or
                            SpotHero API Platform.
                        </li>
                        <li>
                            Re-distribute the APIs or Features to websites or
                            software that can be reasonable viewed as SpotHero’s
                            competitors.
                        </li>
                        <li>
                            Install spyware on another’s computer or device;
                            cause spyware to be installed on another person’s
                            computer or device.
                        </li>
                        <li>
                            Reformat or frame any portion of the SpotHero API
                            Platform.
                        </li>
                        <li>
                            Take any action that imposes, or may impose, in
                            SpotHero’s sole discretion, an unreasonable or
                            disproportionately large load on the SpotHero API
                            Platform or the IT infrastructure used to operate
                            and make the SpotHero API Platform available.
                        </li>
                        <li>
                            Use the APIs, SpotHero API Platform, or Features,
                            intentionally or unintentionally, to violate any
                            applicable local, state, federal or international
                            law or regulation.
                        </li>
                        <li>
                            Collect any information about other users (including
                            Registration Information and Personal Information).
                        </li>
                        <li>
                            Publish, link to, sell, otherwise distribute, or
                            place a link in the Application or in connection
                            with the Application on the same page, in close
                            proximity to, or in connection with any
                            Objectionable Content. For purposes of these Terms,
                            &quot;Objectionable Content&quot; means any
                            material, including textual, audio or video
                            material, which is offensive (including hate speech
                            or violence against a particular group of people);
                            contains any nudity, explicit violence or sexual
                            material; contains depictions of violent or sexual
                            acts; is defamatory to any group or individual; or
                            promotes alcohol, tobacco, or gambling/lottery.
                        </li>
                        <li>
                            Make any representations, either express or implied,
                            or create an appearance that a visitor to Your
                            Application is visiting SpotHero, including but not
                            limited to, &quot;framing&quot; or
                            &quot;wrapping&quot; SpotHero’s website or other
                            content in any manner without first obtaining in
                            advance our express written permission.
                        </li>
                        <li>
                            Bid on the SpotHero Marks, the word SpotHero, or any
                            similar variations at any website that provides
                            search engine services and that results in driving
                            traffic to any website, other than ours, including
                            Your website and/or Application.
                        </li>
                        <li>
                            Without the prior written approval of SpotHero, use
                            the word SpotHero or any of the SpotHero Marks, the
                            APIs, or Features in an advertisement that is not
                            created or provided by SpotHero in any way that
                            might suggest or imply or mislead or is likely to
                            mislead a visitor to Your website or Application
                            into believing that SpotHero or any related entity
                            was the creator or sponsor of such advertisement.
                        </li>
                        <li>
                            Forward, redistribute, or otherwise repurpose the
                            APIs or Features to any third party.
                        </li>
                    </ul>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Confidential Information.
                    </h3>
                    <p>
                        You will not use or disclose Confidential Information
                        other than as permitted by these Terms. If You
                        experience a breach of Confidential Information, You
                        agree to notify SpotHero within 48 hours thereof,
                        cooperate with SpotHero in any investigation into the
                        same, and promptly take all reasonable measures to
                        remedy such breach. Your confidentiality obligations
                        shall survive the termination of your participation with
                        the APIs, SpotHero API Platform, or Features for three
                        years. You acknowledge that monetary damages will not be
                        a sufficient remedy for unauthorized use or disclosure
                        of Confidential Information and that SpotHero will be
                        entitled (without waiving any other rights or remedies)
                        to any injunctive or equitable relief as may be deemed
                        proper by a court of competition jurisdiction, without
                        obligation to post a bond. Any information provided by
                        You to SpotHero is considered to be non-confidential.
                    </p>
                    <p>
                        You further agree to implement and maintain reasonable
                        security measures, procedures and practices to protect
                        such Confidential Information and You agree to maintain
                        the confidentiality of and safekeep and protect
                        SpotHero&apos;s Confidential Information (and any other
                        information provided by SpotHero to You) in accordance
                        with all applicable laws, regulations, rules and
                        guidelines, including but not limited to, privacy
                        protections under the Act.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Modifications.</h3>
                    <p>
                        We reserve the right to and may amend these Terms at any
                        time for any reason and without prior notice. Any such
                        amendments will become effective upon the date they are
                        posted. Your continued use of the APIs or Features shall
                        constitute Your acceptance of the amended Terms. If you
                        do not wish to be bound by the amended Terms, You must
                        terminate use of the APIs or Features.
                    </p>
                    <p>
                        You agree and understand that the APIs or Features may
                        be modified or terminated by SpotHero at any time in our
                        sole discretion without any prior notice. Any new
                        features, enhancements or modifications to the APIs or
                        Features shall be subject to the applicable Terms posted
                        at that time. SpotHero shall bear no responsibility for
                        any modifications or terminations which may affect Your
                        ability to access and/or use the APIs or Features.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Communications.</h3>
                    <p>
                        SpotHero may send You certain communications in
                        connection with Your use of the APIs or Features. Please
                        review the applicable API documentation for information
                        about opting out of certain types of communications.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Feedback.</h3>
                    <p>
                        If You provide feedback or suggestions about the APIs,
                        Features, or SpotHero API Platform, SpotHero (and those
                        we allow) may use such information without obligation to
                        You.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Open Source Software.</h3>
                    <p>
                        Some of the software required by or included in our APIs
                        or Features may be offered under an open source license.
                        Open source software licenses constitute separate
                        written agreements. For certain APIs or Features, open
                        source software is listed in the documentation. To the
                        limited extent the open source software license
                        expressly supersedes these Terms, the open source
                        license instead sets forth your agreement with SpotHero
                        for the applicable open source software.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Non-Exclusivity.</h3>
                    <p>
                        You acknowledge and agree that SpotHero may develop
                        products or services that may compete with Your
                        Application or any other products or services You may
                        offer.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Monitoring and Enforcement.
                    </h3>
                    <p>
                        YOU CONSENT AND AGREE THAT SPOTHERO MAY MONITOR OR AUDIT
                        YOUR APPLICATION OR USE OF THE APIS, FEATURES, OR
                        SPOTHERO API PLATFORM. This monitoring may include
                        SpotHero accessing and using Your Application. You will
                        not interfere with or otherwise attempt to block any
                        such monitoring or audit. SpotHero may use any technical
                        means to overcome such interference. Further, You agree
                        to cooperate with any monitoring or audit as requested
                        by SpotHero. SpotHero reserves the right to take
                        appropriate action as necessary, including suspension of
                        Your access to the SpotHero API Platform or legal action
                        (in SpotHero’s sole discretion) against anyone who
                        violates these Terms.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Ownership.</h3>
                    <p>
                        SpotHero does not acquire ownership in Your Application,
                        and by using the APIs, Features or SpotHero API
                        Platform, You do not acquire ownership of any rights
                        held by SpotHero in the APIs, Features, or SpotHero API
                        Platform, or any customer information, documentation or
                        content that is accessed through our APIs.
                    </p>
                    <p>
                        There are no implied licenses under these Terms, and any
                        rights not expressly granted to You pursuant to these
                        Terms are reserved by SpotHero. You agree not to take
                        any action inconsistent with SpotHero’s ownership of any
                        Feature, API, SpotHero website, the SpotHero API
                        Platform, or the SpotHero Marks. NOTHING IN THESE TERMS
                        SHALL BE DEEMED TO GRANT YOU OR ANY OTHER PARTY ANY
                        RIGHT IN OR TO ANY PATENT, TRADEMARK, COPYRIGHT, TRADE
                        SECRET, OR OTHER PROPRIETARY RIGHT OF SPOTHERO.
                    </p>
                    <p>
                        Any trademarks of third parties that appear on the
                        SpotHero API Platform may or may not be affiliated with,
                        connected to, or sponsored by SpotHero. You may not use,
                        copy, modify or display any of the trademarks, service
                        marks, names or logos appearing on the SpotHero API
                        Platform without the express written permission of the
                        rightful owner.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Third Party Content Through APIs.
                    </h3>
                    <p>
                        The APIs may contain some third party content (such as
                        text, images, videos, audio, or software). This third
                        party content is the sole responsibility of the person
                        that makes it available. We may sometimes review this
                        content to determine whether it is illegal or violates
                        our policies or the Terms and may remove or refuse to
                        display content. Content accessible through the APIs may
                        be subject to intellectual property rights, and if so,
                        you may not use it unless you are licensed to do so by
                        the owner of that content or are otherwise permitted by
                        law.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Copyright.</h3>
                    <p>
                        If You believe anything on the SpotHero API Platform or
                        on a SpotHero website infringes on the work of another,
                        or you have a claim under the Digital Millennium
                        Copyright Act, You agree to send us the following
                        materials at the contact information listed below:
                    </p>
                    <ul className={styles.bullets}>
                        <li>
                            A description and copy of the copyrighted work You
                            claim has been infringed;
                        </li>
                        <li>
                            A description of where the material You claim is
                            infringed is located;
                        </li>
                        <li>
                            Your address, telephone number, and email address;
                        </li>
                        <li>
                            A statement by You that You have a good faith belief
                            that the disputed use is not authorized by the
                            copyright owner, its agent, or the law;
                        </li>
                        <li>
                            A statement by You, made under penalty of perjury,
                            that the above information in the notice is accurate
                            and that You are the copyright owner or authorized
                            to act on teh copyright owner’s behalf.
                        </li>
                    </ul>
                    <p>The above notice should be addressed to:</p>
                    <div className={styles.contactInfo}>
                        <p>
                            <em>SpotHero:</em>
                        </p>
                        <p>
                            <em>125 S. Clark St.</em>
                        </p>
                        <p>
                            <em>Chicago, Illinois 60603</em>
                        </p>
                    </div>
                    <div className={styles.contactInfo}>
                        <p>
                            Phone: <strong>(312) 566-SPOT (7768)</strong>
                        </p>
                        <p>
                            E-mail: <strong>support@spothero.com</strong>
                        </p>
                    </div>
                </li>
                <li>
                    <h3 className={styles.heading}>Termination.</h3>
                    <p>
                        You may cease using the APIs, Features, and/or SpotHero
                        API Platform at any time with or without notice. Upon
                        any termination of the Terms or discontinuation of your
                        access to the APIs, Features, and/or SpotHero API
                        Platform, you will immediately stop using the APIs,
                        cease all use of the Features, and delete any cached or
                        stored content permitted only under these Terms.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>
                        Links to Third Party Sites.
                    </h3>
                    <p>
                        We may display on the SpotHero API Platform links to
                        third party sites. SpotHero is not responsible for the
                        content of such links or websites, nor any materials
                        relating thereto or contained therein. SpotHero’s
                        display of the same does not constitute SpotHero’s
                        endorsement. IN NO EVENT WILL SPOTHERO WILL LIABLE,
                        DIRECTLY OR INDIRECTLY, TO ANYONE FOR ANY DAMAGE OR LOSS
                        ARISING FROM OR RELATING TO ANY USE, CONTINUED USE OR
                        RELIANCE ON ANY LINKED THIRD PARTY SITE OR ANY LINK
                        CONTAINED IN A LINKED SITE.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Disclaimer of Warranty.</h3>
                    <p>
                        THE APIS, FEATURES, AND/OR SPOTHERO API PLATFORM ARE
                        PROVIDED &quot;AS IS,&quot; &quot;WITH ALL FAULTS,&quot;
                        AND &quot;WHERE IS&quot; AND SPOTHERO DISCLAIMS ALL
                        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                        STATUTORY OR OTHERWISE, WITH RESPECT TO THE APIS,
                        FEATURES, AND/OR SPOTHERO API PLATFORM OR ANY OTHER
                        MATTER, INCLUDING WITHOUT LIMITATION THE APIS CONDITION
                        OR QUALITY OR THE WARRANTIES OR CONDITIONS OF
                        NONINFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
                        PARTICULAR PURPOSE.
                    </p>
                    <p>
                        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE
                        THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. EXCEPT AS
                        EXPRESSLY PROVIDED FOR IN THE TERMS, TO THE EXTENT
                        PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES, GUARANTEES,
                        CONDITIONS, REPRESENTATIONS, AND UNDERTAKINGS.
                    </p>
                    <p>
                        SPOTHERO DOES NOT ENDORSE OR RECOMMEND ANY APPLICATIONS
                        AND WE DO NOT ENDORSE THE VIEWS, PRODUCTS, OR SERVICES
                        ANY APPLICATION OFFERS. PARTICIPATION OR ACCESS TO THE
                        APIS, FEATURES, OR SPOTHERO API PLATFORM DOES NOT
                        CONSTITUTE OR IMPLY AN ENDORSEMENT OF ANY APPLICATION BY
                        SPOTHERO. SPOTHERO IS NOT RESPONSIBLE FOR THE
                        AVAILABILITY OR CONTENT OF ANY APPLICATION.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Limitation of Liability.</h3>
                    <p>
                        IN NO EVENT SHALL SPOTHERO, ITS AFFILIATES, OR THE
                        RESPECTIVE SHAREHOLDERS, DIRECTORS, MANAGERS, OFFICERS,
                        MEMBERS, EMPLOYEES, AGENTS OR REPRESENTATIVES OF ANY OF
                        THE FOREGOING, BE LIABLE TO YOU OR ANY THIRD PARTY FOR
                        DAMAGES OF ANY KIND (INCLUDING BUT NOT LIMITED TO, ANY
                        DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, OR
                        CONSEQUENTIAL DAMAGES OR DAMAGES FOR LOSS OF PROFITS,
                        GOODWILL OR REVENUE, BUSINESS INTERRUPTION, OR LOSS OF
                        DATA) ARISING OUT OF THE USE OF, ACCESS TO, RELIANCE ON,
                        UNAVAILABILITY OF, INABILITY OT USE OR IMPROPER USE OF
                        THE SPOTHERO API PLATFORM, THE APIS, OR ANY FEATURES,
                        SPOTHERO WEBSITE, OR OTHER INFORMATION. TO THE EXTENT
                        PERMITTED BY LAW, THE TOTAL LIABILITY OF SPOTHERO, ITS
                        AFFILIATES, OR THE RESPECTIVE SHAREHOLDERS, DIRECTORS,
                        MANAGERS, OFFICERS, MEMBERS, EMPLOYEES, AGENTS OR
                        REPRESENTATIVES OF ANY OF THE FOREGOING, OR ANY CLAIM
                        UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES,
                        IS LIMITED TO ONE HUNDRED UNITED STATES DOLLARS
                        ($100.00).
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>Indemnification.</h3>
                    <p>
                        You agree to indemnify, defend, and hold SpotHero and
                        its respective affiliates, licensors, directors,
                        officers, members, mangers, employees, agents, and
                        representatives, harmless from and against any and all
                        claims, allegations, losses, costs, damages,
                        liabilities, judgments, and expenses, of any nature
                        whatsoever (including attorneys’ fees and costs)
                        (collectively &quot;Claims&quot;) arising from or
                        related to:
                    </p>
                    <ul className={styles.bullets}>
                        <li>
                            Your use or misuse or Your Users use or misuse of
                            the APIs;
                        </li>
                        <li>
                            Your use or misuse or Your Users use or misuse of
                            the SpotHero API Platform;
                        </li>
                        <li>
                            Your use or misuse or Your Users use or misuse of
                            the Features;
                        </li>
                        <li>
                            Your violation or Your Users violation of the Terms;
                        </li>
                        <li>
                            Your Application or the development, maintenance or
                            use of any aspect of the same;
                        </li>
                        <li>
                            Any content or data routed into or used with the
                            APIs, Features, or SpotHero API Platform by You,
                            those acting on Your behalf, or Your Users;
                        </li>
                        <li>
                            Any use of Your Registration Information by You or
                            any third party;
                        </li>
                        <li>
                            The infringement or other violation by You, Your
                            Application, or Your Users of any law or
                            intellectual property or other right of any person
                            or entity.
                        </li>
                    </ul>
                    <p>
                        At SpotHero’s election, You will assume control of the
                        defense and settlement of any Claim subject to
                        indemnification by You, provided that, in such event,
                        SpotHero may at any time thereafter elect to take
                        control of the defense and settlement of any such Claim
                        and You will not settle any Claim without SpotHero’s
                        prior written consent.
                    </p>
                </li>
                <li>
                    <h3 className={styles.heading}>General Legal Terms.</h3>
                    <ol
                        className={classNames(styles.articles, styles.longform)}
                    >
                        <li>
                            <strong>Choice of Law and Forum.</strong> You agree
                            that these Terms and the relationship between the
                            parties shall be governed by the laws of the State
                            of Illinois without regard to its conflict of law
                            provisions and that any and all claims, causes of
                            action, or disputes (regardless of theory) arising
                            out of or relating to these Terms, or the
                            relationship between You and SpotHero, shall be
                            brought exclusively in the courts located in Cook
                            County, Illinois. You and SpotHero agree to submit
                            to the personal jurisdiction of the courts located
                            within Cook County, Illinois and agree to waive any
                            and all objections to the exercise of jurisdiction
                            over the parties by such courts and to venue in such
                            courts.
                        </li>
                        <li>
                            <strong>Statute of Limitations.</strong> To the
                            extent permissible by law, You agree that You must
                            raise any claim or cause of action whosoever arising
                            out of or related to the APIs, Features, these
                            Terms, the SpotHero API Platform, or any SpotHero
                            Website within one year after such claim or cause of
                            action arose; otherwise it shall be forever waived
                            and barred.
                        </li>
                        <li>
                            <strong>Assignment.</strong> These Terms may not be
                            assignable or delegable and cannot be sublicensed
                            except with the other party’s prior written consent,
                            which shall not be unreasonably withheld.
                        </li>
                        <li>
                            <strong>Entire Agreement.</strong> The Terms are the
                            entire agreement between you and SpotHero relating
                            to its subject and supersede any prior or
                            contemporaneous agreements on that subject.
                        </li>
                        <li>
                            <strong>No Joint Venture.</strong> No agency,
                            partnership, joint venture, or employment
                            relationship is created as a result of Your use
                            and/or access of the APIs, Features, or SpotHero API
                            Platform. You do not have any authority to bind
                            SpotHero in any respect whatsoever.
                        </li>
                        <li>
                            <strong>No Third-Party Beneficiaries.</strong>{' '}
                            Nothing in these Terms is intended to, or will,
                            create any third-party beneficiaries, whether
                            intended or incidental, and neither party will make
                            any representations to the contrary.
                        </li>
                        <li>
                            <strong>Headings.</strong> The titles and headings
                            of the various sections and paragraphs in these
                            Terms are intended solely for convenience of
                            reference and are not intended to explain, modify,
                            or place any construction or limitation upon any of
                            the provisions of these Terms.
                        </li>
                        <li>
                            <strong>Severability.</strong> If any of the
                            provisions of these Terms is or becomes illegal,
                            unenforceable, or invalid (in whole or in part for
                            any reason), the remainder of these Terms shall
                            remain in full force and effect without being
                            impaired or invalidated in any way.
                        </li>
                        <li>
                            <strong>No Implied Waiver.</strong> The failure to
                            exercise or enforce any right or provision of these
                            Terms by SpotHero shall not constitute a waiver of
                            the enforcement of such right or provision.
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
};

export default LicenseAgreement;
