/*
EXAMPLE, add the below IN either array:

{
    path: '/reference/health',
    version: 'v1.0',
    name: 'Health API',
    date: '4/12/19'
},

This means, on the /reference/health AND /v1.0/reference/health
it will show an alert message at the top of the page

This can be done for nearly any route

Only the deprecated option supports showing a date
*/

export const deprecated = [];

export const outdated = [];

export default {
    outdated,
    deprecated,
};
