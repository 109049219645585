import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {NavLink as ReactRouterNavLink} from 'react-router-dom';

import {VersionContext} from 'contexts';

const NavLink = ({to, versionLess, ...props}) => {
    const {version} = useContext(VersionContext);

    return (
        <ReactRouterNavLink
            {...props}
            to={`${versionLess ? to : `/${version.slug}${to}`}`}
        />
    );
};

NavLink.propTypes = {
    to: PropTypes.string,
    version: PropTypes.shape({
        to: PropTypes.string,
    }),
    versionLess: PropTypes.bool,
};

export default NavLink;
