import React from 'react';
import PropTypes from 'prop-types';
import styles from './ScrollContainer.module.scss';
import classNames from 'classnames';
import {useScrollContainer} from 'hooks';
import {withRouter} from 'react-router';
import {AppFooter} from 'components';

const ScrollContainer = ({children, className, history}) => {
    const {ref} = useScrollContainer(history);

    return (
        <div
            className={classNames(styles.ScrollContainer, className)}
            ref={ref}
        >
            <div className={styles.content}>{children}</div>
            <AppFooter className={styles.footer} />
        </div>
    );
};

ScrollContainer.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    history: PropTypes.object,
};

export default withRouter(ScrollContainer);
